import React, { Component } from "react";
import Icon_Points from "../../assets/icon/Icon_Points.png";
import ReactPaginate from "react-paginate";
import "./Privilege.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { apiUrl, COLOR_PRIMARY, COLOR_SECONDARY } from "../../constants";
import {
  Container,
  Col,
  Row,
  Nav,
  DropdownButton,
  Image,
  Dropdown,
} from "react-bootstrap";

class Privilege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Category: [],
      selectCategory: 0,
      tags: 0,
      PrivilegeData: [],
      banner: null,
      token: "",
      offset: 0,
      perPage: 12,
      currentPage: 0,
      PrivilegeTotal: 0,
      language: [],
      lang_check: "",
      setToyou: {
        Page: "",
        Detail: "",
        MyPoint: "",
        Reward: "",
        address: "",
      },
    };

    this.onChangeSelectCategory = this.onChangeSelectCategory.bind(this);
  }
  componentDidMount() {
    localStorage.removeItem("lang");
    localStorage.removeItem("lang_check");

    const path = window.location.pathname
    var n = path.search("accountCustomer");
    var check_page = Number(n) > 0 ? "accountCustomer" : "privilege"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          Page: "accountCustomer",
          Detail: "DetailToyou",
          MyPoint: "MyPointToyou",
          Reward: "RewardToyou",
          address: "addressToyou",
        }
      });
    } else {
      this.setState({
        setToyou: {
          Page: "privilege",
          Detail: "Detail",
          MyPoint: "MyPoint",
          Reward: "Reward",
          address: "address",
        }
      });
    }



    let token = this.props.match.params.token;
    let lang = this.props.match.params.lang;
    this.setState({ lang_check: lang, token: token });
    this.getBanner(check_page);
    this.getCategory(token, check_page);
    this.getCustomers(token, lang, check_page);
  }

  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  getBanner = async (check_page) => {
    const apiUrls = check_page === "privilege" ? `${apiUrl}/banner` : `${apiUrl}/toyou/banner`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          banner: res.data[0].image,
        });
      }
    });
  };

  getCategory = async (token, check_page) => {
    const apiUrls = check_page === "privilege" ? `${apiUrl}/privilege/tag-topic` : `${apiUrl}/toyou/privilege/category`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (check_page === "privilege") {
        this.setState({
          Category: res.data[0].tags,
        });
        this.getPrivilege(res.data[0].tags[0].id, check_page);
      } else {
        this.setState({
          Category: res.data,
        });
        this.getPrivilege(res.data[0].id, check_page);
      }
    });
  };

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "privilege") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.getPoint(token);
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            const apiUrllang = `${apiUrl}/uiContent?lang=${lang.toUpperCase()}`;
            const results = axios({
              method: "get",
              url: apiUrllang,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }).then((res) => {
              localStorage.setItem(`lang_${lang.toLowerCase()}`, JSON.stringify(res.data));
              let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
              this.setState({ language: langu });
            });
          } else if (lang === null || lang === undefined) {
            const apiUrllang = `${apiUrl}/uiContent?lang=TH`;
            const results = axios({
              method: "get",
              url: apiUrllang,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }).then((res) => {
              localStorage.setItem(`lang_${lang.toLowerCase()}`, JSON.stringify(res.data));
              let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
              this.setState({ language: langu });
            });
          } else {
            this.props.history.push(`/Error/${this.state.lang_check}`);
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res.data.data.point_txt)
          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            const apiUrllang = `${apiUrl}/toyou/uiContent?lang=${lang.toUpperCase()}`;
            const results = axios({
              method: "get",
              url: apiUrllang,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }).then((res) => {
              localStorage.setItem(`lang_${lang.toLowerCase()}`, JSON.stringify(res.data));
              let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
              this.setState({ language: langu });
            });
          } else if (lang === null || lang === undefined) {
            const apiUrllang = `${apiUrl}/toyou/uiContent?lang=TH`;
            const results = axios({
              method: "get",
              url: apiUrllang,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }).then((res) => {
              localStorage.setItem(`lang_${lang.toLowerCase()}`, JSON.stringify(res.data));
              let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
              this.setState({ language: langu });
            });
          } else {
            this.props.history.push(`/Error/${this.state.lang_check}`);
          }
        })
    }
  };

  getPoint = async (token) => {
    const apiUrls = `${apiUrl}/account/getPoint`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        this.setState({
          point: res.data.point,
        });
      })
      .catch((err) => {
        this.props.history.push(`/Error/${this.state.lang_check}`);
      });
  };

  onChangeSelectCategory = async (tags, key) => {
    this.setState({
      selectCategory: key,
      currentPage: 0,
      offset: 0,
    });

    this.getPrivilege(tags, this.state.setToyou.Page);
  };

  getCategoryData = () => {
    const { Category, selectCategory } = this.state;
    if (Category) {
      const data = Category;
      data.sort((a, b) => (a.tag_weight > b.tag_weight ? 1 : -1));
      return data.map((item, key) => (
        <span
          key={key}
          className="btn col-4 col-xs-3 col-sm-4 col-md-4 col-lg-2 "
          style={{
            outline: "none",
            background: "none",
            display: "inline-block",
            border: "none",
            textAlign: "center",
            width: "100%",
            backgroundColor: key === selectCategory ? `${COLOR_PRIMARY}` : null,
          }}
          onClick={() => this.onChangeSelectCategory(item.id, key)}
        >
          <div>
            <img
              src={key === selectCategory ? item.image_hover : item.image}
              style={{
                height: "32px",
                width: "32px",
                marginTop: "10px",
                marginBottom: "22px",
              }}
            />
          </div>

          <span
            className="font-category"
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontSize: "18px",
              fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                ? "DB_Heavent_Bd"
                : "Delivery_A"
                }`,
              color:
                key === selectCategory
                  ? `${COLOR_SECONDARY}`
                  : "rgba(166, 166, 166, 1)",
              fontWeight: "bold",
            }}
          >
            {this.state.lang_check.toLowerCase() === "th"
              ? item.tag_name_th
              : item.tag_name_en ? item.tag_name_en : item.tag_name}
          </span>
        </span>
      ));
    }
  };

  getPrivilege = async (tags, check_page) => {
    const apiUrls = check_page === "privilege" ? `${apiUrl}/privilege/deals?page=1&limit=10000&tags=${tags}` : `${apiUrl}/toyou/privilege/deals?page=1&limit=10000&tags=${tags}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${this.state.token}`,
      },
    }).then((res) => {
      console.log(res)
      this.setState({
        tags: tags,
        PrivilegeData: res.data.data,
        pageCount: Math.ceil(res.data.total / this.state.perPage),
      });
    });
  };

  getPrivilegeData = () => {
    const { PrivilegeData, token, language } = this.state;

    if (PrivilegeData) {
      return PrivilegeData.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      ).map((item, key) => (
        <div
          key={key}
          className="col-12 col-sm-6 col-md-4 col-xl-3"
          style={{ textAlign: "center" }}
        >
          <div className="d-flex justify-content-between"></div>
          <div
            className="btn nav-link"
            onClick={() => {
              this.detail(item.id, token, item.is_delivery)
            }}
          >
            <div className="row" style={{ textAlign: "center" }}>
              <img
                src={item.deal_image}
                style={{
                  height: "200px",
                  width: "200px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div
              className="row"
              style={{ textAlign: "center", marginTop: "12px" }}
            >
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    }`,
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                {this.state.lang_check.toLowerCase() === "th"
                  ? item.deal_name_th
                  : item.deal_name}
              </span>
            </div>
            <div className="row" style={{ textAlign: "center" }}>
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: `${COLOR_SECONDARY}`,
                }}
              >
                {item.actualCodeRemaining === 0 ?
                  <>
                    {language.length > 0
                      ? this.getLanguage(1010507)
                      : "สินค้าหมด"}
                  </>
                  : (
                    <>
                      {Number(item.point) > 0 ? this.setDegitSaraly(item.point) : 0}{" "}
                      {language.length > 0 ? this.getLanguage(1010101) : "Point"}
                    </>
                  )
                }
              </span>
            </div>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col" style={{ textAlign: "center" }}>
            <span
              style={{
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Bd"
                  : "Delivery_A"
                  }`,
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "auto",
                marginRight: "auto",
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              No item
            </span>
          </div>
        </div>
      );
    }
  };

  detail = async (id, token, is_delivery) => {
    this.props.history.push({
      pathname: `/${this.state.setToyou.Detail}/${id}/${token}/${this.state.lang_check}`,
      state: {
        is_delivery: is_delivery,
      },
    });
  };
  handleSelect = (eventKey) => { };

  getRewardRedemption = async (check_page) => {
    const apiUrls = check_page === "privilege" ? `${apiUrl}/privilege/history?limit=10000&page=1` : `${apiUrl}/toyou/privilege/history?limit=10000&page=1`;
    var url = window.location.href;
    let token = url.split("/");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token[4]}`,
      },
    }).then((res) => {
      this.setState({
        data: res.data.data,
      });
    });
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset }, () => {
      this.getRewardRedemption(this.state.setToyou.Page);
    });
  };

  setDegitSaraly = (point) => {
    if (point) {
      var parts = point.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let pointa = parts.join(".");
      return pointa;
    } else {
      return " ";
    }
  };

  render() {
    const { language } = this.state;
    let paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={<span className="gap">...</span>}
          pageCount={this.state.pageCount}
          onPageChange={this.handlePageClick}
          forcePage={this.state.currentPage}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <Container>
        <Nav className="justify-content-end mt-3 mb-3">
          <Nav.Item>
            <div
              style={{
                color: "rgba(166, 166, 166, 1)",
                paddingTop: "6px",
                paddingRight: "6px",
              }}
            >
              <img
                src={Icon_Points}
                style={{ height: "25px", width: "25px" }}
              />
              <span
                style={{
                  fontSize: "18px",
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  color: "rgba(74, 74, 74, 1)",
                  fontWeight: "bold",
                }}
              >
                {" "}

                {this.state.point
                  ? this.state.point
                  : 0}{" "}
                {language.length > 0 ? this.getLanguage(1010101) : "Point"}
              </span>
            </div>
          </Nav.Item>
          <Nav.Item>
            <DropdownButton
              title={
                <span
                  style={{
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Med"
                      : "Delivery_A"
                      }`,
                    fontSize: "18px",
                    color: `${COLOR_SECONDARY}`,
                  }}
                >
                  {language.length > 0
                    ? this.getLanguage(1010102)
                    : "My Privilege"}
                </span>
              }
              id="basic-nav-dropdown"
              variant="outline"
              onSelect={this.handleSelect}
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderStyle: "solid",
                fontSize: "18px",
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Med"
                  : "Delivery_A"
                  }`,
                borderColor: `${COLOR_SECONDARY}`,
                borderRadius: "5px",
              }}
            >
              <Dropdown.Item
                href={`/${this.state.setToyou.MyPoint}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0 ? this.getLanguage(1010114) : "My Points"}
              </Dropdown.Item>

              <Dropdown.Item
                href={`/${this.state.setToyou.Reward}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0 ? this.getLanguage(1010103) : "HISTORY"}
              </Dropdown.Item>
              <Dropdown.Item
                href={`/${this.state.setToyou.address}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0
                  ? this.getLanguage(1010104)
                  : "DELIVERY ADDRESS"}
              </Dropdown.Item>
            </DropdownButton>
          </Nav.Item>
        </Nav>

        {this.state.banner ? (
          <Row>
            <Col
              className="col"
              style={{ paddingLeft: "5px", paddingRight: "5px" }}
            >
              <Image src={this.state.banner} />
            </Col>
          </Row>
        ) : (
          ""
        )}

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col" style={{ textAlign: "center" }}>
            <span
              style={{
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Bd"
                  : "Delivery_A"
                  }`,
                fontSize: "2rem",
                color: `${COLOR_SECONDARY}`,
              }}
            >
              {language.length > 0
                ? this.getLanguage(1010105)
                : "DHL Privilege"}
            </span>{" "}
            <span
              style={{
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Bd"
                  : "Delivery_A"
                  }`,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              {language.length > 0
                ? this.getLanguage(1010106)
                : "Daily specials for DHL Express customers."}
            </span>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: "center" }}>
            <span
              style={{
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Med"
                  : "Delivery_A"
                  }`,
                fontSize: "1.5rem ",
                color: "rgba(0, 0, 0, 1)",
              }}
              className="font-main"
            >
              {language.length > 0
                ? this.getLanguage(1010107)
                : "Various privileges at DHL Express, selected for your every lifestyle."}
            </span>{" "}
          </div>
        </div>

        <Row style={{ margin: "20px" }}>{this.getCategoryData()}</Row>

        <div className="row" style={{ marginTop: "58px" }}>
          {this.getPrivilegeData()}
        </div>
        <div
          style={{
            fontFamily: `${this.state.lang_check.toLowerCase() === "th"
              ? "DB_Heavent_Med"
              : "Delivery_A"
              }`,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          {paginationElement}
        </div>
      </Container>
    );
  }
}

export default withRouter(Privilege);
