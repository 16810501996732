import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";

import "./Address.css";
import Modal from "@material-ui/core/Modal";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";

class EditAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      addressData: [],
      id: props.location.state.item.id,
      locationName: props.location.state.item.location_name,
      firstName: props.location.state.item.first_name,
      lastName: props.location.state.item.last_name,
      address: props.location.state.item.address1,
      apartment: props.location.state.item.address2,
      address3: props.location.state.item.address3,
      phone: props.location.state.item.phone,
      postalCode: props.location.state.item.post_code,
      checkbox: props.location.state.item.is_save,
      shippingAddressData: -1,
      token: "",
      open: false,
      message: "",
      lang_check: "",
      language: [],
      locations: [],
      province: [],
      district: [],
      sub_district: [],
      sub_district_selete: [],
      province_cer: props.location.state.item.province,
      district_cer: props.location.state.item.district,
      sub_district_cer: props.location.state.item.subDistrict,
      districtData: {},
      province_id: "",
      province_select: {},
      events: [],
      setToyou: {
        address: "",
      },
    };
  }

  componentDidMount() {
    const path = window.location.pathname
    var n = path.search("EditAddressToyou");
    var check_page = Number(n) > 0 ? "addressToyou" : "address"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          address: "addressToyou",
        }
      });
    } else {
      this.setState({
        setToyou: {
          address: "address",
        }
      });
    }


    var url = window.location.href;
    let token = url.split("/");
    let lang = url.split("/");
    let langu = JSON.parse(localStorage.getItem("lang_th"));

    this.setState({ language: langu });
    this.setState({ lang_check: lang[5] });
    this.getCustomers(token[4], lang[5], check_page);
    this.setState({
      token: token[4],
    });
    this.getLocationFrist(this.props.location.state.item.post_code);
  }

  getLocationFrist(postcode) {
    const apiUrls = `${apiUrl}/location/zipcode/${postcode}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.data.length === 1) {
        this.setState({
          events: res.data,
          postcode: this.props.location.state.item.post_code,
          districtData: res.data.length === 1 ? res.data[0] : {},
          locations: res.data,
          province:
            res.data.length > 0
              ? [
                {
                  id: res.data[0].province.id,
                  code: res.data[0].province.code,
                  name_th: res.data[0].province.name_th,
                  name_en: res.data[0].province.name_en,
                  geography_id: res.data[0].province.geography_id,
                },
              ]
              : [],
        });
        this.getSubDistricts(res.data[0].code);
      } else if (res.data.length === 2) {
        this.setState({
          events: res.data,
          postcode: this.props.location.state.item.post_code,
          districtData: res.data.length === 1 ? res.data[0] : {},
          locations: res.data,
          province:
            res.data[0].province.id === res.data[1].province.id
              ? [
                {
                  id: res.data[0].province.id,
                  code: res.data[0].province.code,
                  name_th: res.data[0].province.name_th,
                  name_en: res.data[0].province.name_en,
                  geography_id: res.data[0].province.geography_id,
                },
              ]
              : [
                {
                  id: res.data[0].province.id,
                  code: res.data[0].province.code,
                  name_th: res.data[0].province.name_th,
                  name_en: res.data[0].province.name_en,
                  geography_id: res.data[0].province.geography_id,
                },
                {
                  id: res.data[1].province.id,
                  code: res.data[1].province.code,
                  name_th: res.data[1].province.name_th,
                  name_en: res.data[1].province.name_en,
                  geography_id: res.data[1].province.geography_id,
                },
              ],
        });
        this.getSubDistricts(this.props.location.state.item.district.code);
      } else {
        this.setState({
          events: res.data,
          postcode: this.props.location.state.item.post_code,
          districtData: res.data.length === 1 ? res.data[0] : {},
          locations: res.data,
          province:
            res.data.length > 0
              ? [
                {
                  id: res.data[0].province.id,
                  code: res.data[0].province.code,
                  name_th: res.data[0].province.name_th,
                  name_en: res.data[0].province.name_en,
                  geography_id: res.data[0].province.geography_id,
                },
              ]
              : [],
        });
        this.getSubDistricts(this.props.location.state.item.district.code);
      }
    });
  }

  getLocation(postcode) {
    if (this.state.postcode !== postcode) {
      this.clear();
      const apiUrls = `${apiUrl}/location/zipcode/${postcode}`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {

        var province_check = [];
        var datas = [];
        if (res.data.length > 0) {

          res.data.map((data, key) => {
            datas = {
              code: data.code,
              id: data.id,
              name_en: data.name_en,
              name_th: data.name_th,
              province: data.province,
              province_id: data.province_id,
              subDistricts: data.subDistricts,
            };
            province_check.push(data);
          });

          var province_data_all = [];
          province_check.map((data, key) => {
            if (data.province_id === 65 || data.province_id === 66) {
              datas = {
                province: data.province,
                province_id: data.province_id,
              };

              province_data_all.push(datas);
            } else {
              const isLargeNumber = (element) => element === data.province_id;
              if (province_data_all.length > 0) {
                let check = province_data_all.findIndex(isLargeNumber);
                if (check === 0) {
                  datas = {
                    province: data.province,
                    province_id: data.province_id,
                  };

                  province_data_all.push(datas);
                }
                this.getDistricts(data.province_id);
              } else {
                datas = {
                  province: data.province,
                  province_id: data.province_id,
                };

                province_data_all.push(datas);
                // this.getDistricts(data.province_id);
              }
            }
          });

          this.setState({
            locations: [],
            events: res.data,
            postcode: postcode,
            province:
              province_data_all.length === 2
                ? [
                  {
                    id: province_data_all[0].province.id,
                    code: province_data_all[0].province.code,
                    name_th: province_data_all[0].province.name_th,
                    name_en: province_data_all[0].province.name_en,
                    geography_id: province_data_all[0].province.geography_id,
                  },
                  {
                    id: province_data_all[1].province.id,
                    code: province_data_all[1].province.code,
                    name_th: province_data_all[1].province.name_th,
                    name_en: province_data_all[1].province.name_en,
                    geography_id: province_data_all[1].province.geography_id,
                  },
                ]
                : [
                  {
                    id: province_data_all[0].province.id,
                    code: province_data_all[0].province.code,
                    name_th: province_data_all[0].province.name_th,
                    name_en: province_data_all[0].province.name_en,
                    geography_id: province_data_all[0].province.geography_id,
                  },
                ],
          });

        }
      });
    }

  }

  getDistricts(id_pro) {
    const { events } = this.state

    var districts = [];
    var datas = [];

    events.map((data, key) => {
      if (data.province_id === Number(id_pro)) {
        datas = {
          code: data.code,
          id: data.id,
          name_en: data.name_en,
          name_th: data.name_th,
          province: data.province,
          province_id: data.province_id,
          subDistricts: data.subDistricts,
        };
        districts.push(datas);
      }
    });

    this.setState({
      districtData: districts.length === 1 ? districts[0] : {},
      locations: districts,
      locations_district: districts,
      province_id: Number(id_pro),
    });

  }

  getSubDistricts(district_id) {
    var item = this.state.locations.find((l) => l.code === district_id);
    this.setState({
      district: item,
      sub_district_selete: item !== undefined ? item.subDistricts : [],
    });
  }

  handleSubmit = (values, { props = this.props, setSubmitting }) => {
    let sub_district_find = this.state.sub_district_selete.find(
      (l) => l.id === values.sub_district
    );
    this.setState({
      locationName: values.location_name,
      firstName: values.first_name,
      lastName: values.last_name,
      address: values.address1,
      apartment: values.address2,
      phone: values.phone,
      address3: values.address3,
      postalCode: values.post_code,
      sub_district: sub_district_find,
    });

    setSubmitting(false);
    this.EditAddress();
    return;
  };

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "address") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }

        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {

          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });
    }
  };

  EditAddress = async () => {
    var url = window.location.href;
    let token = url.split("/");
    if (
      (this.state.locationName,
        this.state.address,
        this.state.firstName,
        this.state.lastName,
        this.state.phone,
        this.state.province_select,
        this.state.postalCode)
    ) {

      const apiUrls = this.state.setToyou.address === "address" ? `${apiUrl}/customerAddress/${this.state.id}` : `${apiUrl}/toyou/siteAddress/${this.state.id}`;
      const result = await axios({
        method: "put",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token[4]}`,
        },
        data: {
          location_name: this.state.locationName,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address1: this.state.address,
          address2: this.state.apartment === "" ? " " : this.state.apartment,
          address3: this.state.address3 === "" ? " " : this.state.address3,
          phone: this.state.phone,
          post_code: this.state.postalCode,
          province: this.state.province_select,
          district: this.state.district,
          sub_district: this.state.sub_district,
          province_id: Number(this.state.province_id),
          district_id: this.state.district.id,
          sub_district_id: this.state.sub_district.id,
          post_code: this.state.postalCode,
        },
      })
        .then(async (res) => {
          this.props.history.push(
            `/${this.state.setToyou.address}/${token[4]}/${this.state.lang_check}`
          );
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              open: true,
              message: error.response.data.message,
            });
          }
        });

    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  getPrvinceData(province) {
    let items = this.state.locations.find(
      (l) => l.province.id === Number(province)
    );
    this.setState({
      province_id: Number(province),
      province_select: items.province,
    });
  }

  clear() {
    this.setState({
      district: [],
      districtData: [],
      district_cer: {},
      sub_district: [],
      sub_district_selete: [],
      sub_district_cer: {},
      locations: [],
      province: [],
      province_cer: {},
      locations_district: [],
      locations: [],
      postcode: "",
      province_cer: {},
      postalCode: "",
      district_cer: {},
      sub_district_cer: {},
    });
  }

  render() {
    const { open, events } = this.state;
    return (
      <div
        className="container"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="col">
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    }`,
                  fontSize: "26px",
                }}
              >
                {this.state.language.length > 0
                  ? this.state.language[48].content
                  : null}
              </span>
            </div>
            <div className="col">
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  fontSize: "20px",
                  color: "rgba(72, 72, 72, 1)",
                }}
              >
                {this.state.language.length > 0
                  ? this.state.language[50].content
                  : null}
              </span>

              <Formik
                initialValues={{
                  location_name: this.state.locationName,
                  address1: this.state.address,
                  address2: this.state.apartment,
                  address3: this.state.address3,
                  first_name: this.state.firstName,
                  last_name: this.state.lastName,
                  phone: this.state.phone,
                  province: this.state.province_cer.id,
                  post_code: this.state.postalCode,
                  district: this.state.district_cer.code,
                  sub_district: this.state.sub_district_cer.id,
                }}
                validate={(values) => {
                  let errors = {};
                  if (!values.location_name)
                    errors.location_name =
                      this.state.language.length > 0
                        ? this.getLanguage(1010412)
                        : "";
                  if (typeof values.location_name !== "undefined") {
                    var pattern = new RegExp(/^[a-zA-Z0-9 -/]+$/);
                    if (!pattern.test(values.location_name)) {
                      errors["location_name"] = "location name must be english and number only.";
                    }
                  }
                  if (!values.address1)
                    errors.address1 =
                      this.state.language.length > 0
                        ? this.getLanguage(1010413)
                        : "";
                  if (typeof values.address1 !== "undefined") {
                    var pattern = new RegExp(/^[a-zA-Z0-9 -/]+$/);
                    if (!pattern.test(values.address1)) {
                      errors["address1"] = "address 1 must be english and number only.";
                    }
                  }

                  if (typeof values.address2 !== "undefined") {
                    var pattern = new RegExp(/^[a-zA-Z0-9 -/]+$/);
                    if (!pattern.test(values.address2)) {
                      errors["address2"] = "address 2 must be english and number only.";
                    }
                  }

                  if (typeof values.address3 !== "undefined") {
                    var pattern = new RegExp(/^[a-zA-Z0-9 -/]+$/);
                    if (!pattern.test(values.address3)) {
                      errors["address3"] = "address 3 must be english and number only.";
                    }
                  }

                  if (!values.first_name)
                    errors.first_name =
                      this.state.language.length > 0
                        ? this.getLanguage(1010414)
                        : "";

                  if (!values.last_name)
                    errors.last_name =
                      this.state.language.length > 0
                        ? this.getLanguage(1010415)
                        : "";

                  if (!values.phone) {
                    errors["phone"] =
                      this.state.language.length > 0
                        ? this.getLanguage(1010416)
                        : "";
                  }

                  if (typeof values.phone !== "undefined") {
                    var pattern = new RegExp(/^[0-9\b]+$/);
                    if (!pattern.test(values.phone)) {
                      errors["phone"] =
                        this.state.language.length > 0
                          ? this.getLanguage(1010416)
                          : "";
                    } else if (values.phone.length < 9) {
                      errors["phone"] =
                        this.state.language.length > 0
                          ? this.getLanguage(1010422)
                          : "";
                    }
                  }

                  if (!values.post_code) {
                    errors.post_code =
                      this.state.language.length > 0
                        ? this.getLanguage(1010423)
                        : "";
                  }

                  if (typeof values.post_code !== "undefined") {
                    var pattern = new RegExp(/^[0-9\b]+$/);
                    if (!pattern.test(values.post_code)) {
                      errors["post_code"] =
                        this.state.language.length > 0
                          ? this.getLanguage(1010423)
                          : "";
                    }

                    if (values.post_code.length === 5) {
                      this.getLocation(values.post_code);
                    }

                    if (values.post_code.length != 5) {
                      this.setState({
                        locations: [],
                        locations_district: [],
                        sub_district: [],
                        district: [],
                        province: [],
                        province_id: "",
                        province_select: {},
                        districtData: {},
                        sub_district_selete: [],
                      });
                    }

                    if (this.state.province.length === 0) {
                      errors.province =
                        this.state.language.length > 0
                          ? this.getLanguage(1010430)
                          : "";
                    }


                    if (values.province) {
                      this.getDistricts(values.province);
                    }

                    if (!values.district && this.state.district.length === 0) {
                      errors.district =
                        this.state.language.length > 0
                          ? this.getLanguage(1010428)
                          : "";
                    }

                    if (values.district) {
                      this.getSubDistricts(values.district);
                    }

                    if (!values.sub_district) {
                      errors.sub_district =
                        this.state.language.length > 0
                          ? this.getLanguage(1010429)
                          : "";
                    }

                    return errors;
                  }
                }}
                onSubmit={this.handleSubmit}
                render={(formProps) => {
                  return (
                    <Form
                      style={{
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Med"
                          : "Delivery_A"
                          }`,
                      }}
                    >
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col">
                          <Field
                            type="text"
                            name="first_name"
                            className="form-control"
                            placeholder={
                              this.state.language.length > 0
                                ? this.getLanguage(1010405)
                                : "First Name"
                            }
                          />
                          <ErrorMessage
                            className="colorError"
                            name="first_name"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="col">
                          <Field
                            type="text"
                            name="last_name"
                            className="form-control"
                            placeholder={
                              this.state.language.length > 0
                                ? this.getLanguage(1010406)
                                : "Last Name"
                            }
                          />
                          <ErrorMessage className="colorError" name="last_name">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>

                      <Field
                        type="text"
                        name="location_name"
                        className="form-control"
                        placeholder={
                          this.state.language.length > 0
                            ? this.getLanguage(1010401)
                            : "Location Name"
                        }
                        style={{ width: "100%", marginTop: "20px" }}
                      />
                      <ErrorMessage className="colorError" name="location_name">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>

                      <Field
                        type="text"
                        name="address1"
                        className="form-control"
                        placeholder={
                          this.state.language.length > 0
                            ? this.getLanguage(1010402)
                            : "Address 1"
                        }
                        style={{ width: "100%", marginTop: "20px" }}
                      />
                      <ErrorMessage className="colorError" name="address1">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>

                      <Field
                        type="text"
                        name="address2"
                        className="form-control"
                        placeholder={
                          this.state.language.length > 0
                            ? this.getLanguage(1010403)
                            : "Address 2"
                        }
                        style={{ width: "100%", marginTop: "20px" }}
                      />
                      <ErrorMessage className="colorError" name="address2">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>

                      <Field
                        type="text"
                        name="address3"
                        className="form-control"
                        placeholder={
                          this.state.language.length > 0
                            ? this.getLanguage(1010404)
                            : "Address 3"
                        }
                        style={{ width: "100%", marginTop: "20px" }}
                      />
                      <ErrorMessage className="colorError" name="address3">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>

                      <Field
                        type="tal"
                        id="phone"
                        name="phone"
                        maxLength="10"
                        className="form-control small-input no-arrow"
                        placeholder={
                          this.state.language.length > 0
                            ? this.getLanguage(1010407)
                            : "Phone"
                        }
                        style={{ width: "100%", marginTop: "20px" }}
                      />
                      <ErrorMessage className="colorError" name="phone">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col">
                          <Field
                            type="text"
                            name="post_code"
                            maxLength="5"
                            placeholder={
                              this.state.language.length > 0
                                ? this.getLanguage(1010409)
                                : "Post code"
                            }
                            className="form-control no-arrow"
                          />
                          <ErrorMessage className="colorError" name="post_code">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="col">
                          {this.state.province.length > 0 ? (
                            <Field
                              name="province"
                              component="select"
                              className="form-control"
                            >
                              <>
                                <option value="">
                                  {this.state.language.length > 0
                                    ? this.getLanguage(1010408)
                                    : "Select Province"}
                                </option>
                                {this.state.province.map((data) => {
                                  return (
                                    <option value={data.id}>
                                      {this.state.lang_check.toLowerCase() ===
                                        "en"
                                        ? data.name_en
                                        : data.name_th}
                                    </option>
                                  );
                                })}
                              </>
                              )
                            </Field>
                          ) : (
                            <Field
                              name="province"
                              component="select"
                              className="form-control"
                            >
                              <option value="">
                                {this.state.language.length > 0
                                  ? this.getLanguage(1010408)
                                  : "Select Province"}
                              </option>
                            </Field>
                          )}
                          <ErrorMessage className="colorError" name="province">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col">
                          <Field
                            name="district"
                            component="select"
                            className="form-control"
                          >
                            {this.state.locations.length === 1 ? (
                              <>
                                <option value="">
                                  {this.state.language.length > 0
                                    ? this.getLanguage(1010426)
                                    : "Please Select District"}
                                </option>
                                <option value={this.state.districtData.code}>
                                  {this.state.lang_check.toLowerCase() === "en"
                                    ? this.state.districtData.name_en
                                    : this.state.districtData.name_th}
                                </option>
                              </>
                            ) : (
                              <>
                                <option value="">
                                  {this.state.language.length > 0
                                    ? this.getLanguage(1010426)
                                    : "Please Select District"}
                                </option>

                                {this.state.locations.map((data, key) => {
                                  return (
                                    <option value={data.code}>
                                      {this.state.lang_check.toLowerCase() ===
                                        "en"
                                        ? data.name_en
                                        : data.name_th}
                                    </option>
                                  );
                                })}
                              </>
                            )}
                          </Field>

                          <ErrorMessage className="colorError" name="district">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="col">
                          <Field
                            name="sub_district"
                            component="select"
                            className="form-control"
                          >
                            <option value="">
                              {this.state.language.length > 0
                                ? this.getLanguage(1010427)
                                : "Please Select Sub district"}
                            </option>
                            {this.state.sub_district_selete.length > 0
                              ? this.state.sub_district_selete.map((data) => {
                                return (
                                  <option value={data.id}>
                                    {this.state.lang_check.toLowerCase() ===
                                      "en"
                                      ? data.name_en
                                      : data.name_th}
                                  </option>
                                );
                              })
                              : null}
                          </Field>

                          <ErrorMessage
                            className="colorError"
                            name="sub_district"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col ">
                          <button
                            className="backaddress"
                            style={{
                              fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                                ? "DB_Heavent_Bd"
                                : "Delivery_A"
                                }`,
                              fontSize: "18px",
                            }}
                            onClick={() =>
                              this.props.history.push(
                                `/${this.state.setToyou.address}/${this.state.token}/${this.state.lang_check}`
                              )
                            }
                          >
                            {this.state.language.length > 0
                              ? this.getLanguage(1010410)
                              : "Back to home"}
                          </button>
                        </div>
                        <div className="col ">
                          <button
                            type="submit"
                            disabled={formProps.isSubmitting}
                            className="backaddress"
                            style={{
                              fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                                ? "DB_Heavent_Bd"
                                : "Delivery_A"
                                }`,
                              fontSize: "18px",
                            }}
                          >
                            {this.state.language.length > 0
                              ? this.getLanguage(1010411)
                              : ""}
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <Modal open={open} disablePortal disableEnforceFocus disableAutoFocus>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center">
                  <svg
                    width="10em"
                    height="10em"
                    viewBox="0 0 16 16"
                    className="bi bi-x-circle-fill text-danger"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                    />
                  </svg>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  {this.state.message}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.handleClose()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EditAddress;
