import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducers from "./reducers";
import logger from "redux-logger";
import font from "./fonts/Delivery_A_Bd.ttf";
import font2 from "./fonts/Delivery_A_Rg.ttf";
import font3 from "./fonts/DB_Heavent_Bd.ttf";
import font4 from "./fonts/DB_Heavent_Med.ttf";
import { Font } from "@react-pdf/renderer";
const store = createStore(reducers, applyMiddleware(thunk, logger));

Font.register(
  {
    family: "Delivery_A",
    src: font,
  },
  {
    family: "Delivery App",
    src: font2,
  },
  {
    family: "DB_Heavent_Bd",
    src: font3,
  },
  {
    family: "DB_Heavent_Med",
    src: font4,
  }
);

const ReduxApp = (
  <Provider store={store}>
    <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
      <App />
    </ClearBrowserCacheBoundary>
  </Provider>
);

ReactDOM.render(ReduxApp, document.getElementById("root"));
