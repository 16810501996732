import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";
import Icon_Edit from "../../assets/icon/Icon_Edit.png";
import Icon_Delete from "../../assets/icon/Icon_Delete.png";
import Modal from "@material-ui/core/Modal";

import "./Address.css";
class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      addressData: [],
      locationName: "",
      firstName: "",
      lastName: "",
      address: "",
      apartment: "",
      address3: "",

      checkbox: false,
      shippingAddressData: -1,
      token: "",
      openCheck: false,
      message: "",
      item_data: null,
      lang_check: "",
      language: [],
      setToyou: {
        Page: "",
        CreateAddress: "",
        EditAddress: "",
        privilege: "",
      },
    };
  }

  componentDidMount() {
    const path = window.location.pathname
    var n = path.search("addressToyou");
    var check_page = Number(n) > 0 ? "addressToYou" : "address"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          Page: "addressToYou",
          CreateAddress: "CreateAddressToyou",
          EditAddress: "EditAddressToyou",
          privilege: "accountCustomer",
        }
      });
    } else {
      this.setState({
        setToyou: {
          Page: "address",
          CreateAddress: "CreateAddress",
          EditAddress: "EditAddress",
          privilege: "privilege",
        }
      });
    }
    var url = window.location.href;
    let token = url.split("/");
    let lang = url.split("/");
    this.setState({ lang_check: lang[5] });
    this.getCustomers(token[4], lang[5], check_page);
    this.setState({ token: token[4] });
    this.getAddress(token[4], check_page);
  }

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "address") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {

          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        })
    }
  };

  getAddress = async (token, check_page) => {
    const apiUrls = check_page === "address" ? `${apiUrl}/customerAddress` : `${apiUrl}/toyou/siteAddress`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        this.setState({
          addressData: res.data,
        });
      })
      .catch((err) => {
      });
  };

  Edit = async (item) => {
    this.props.history.push({
      pathname: `/${this.state.setToyou.EditAddress}/${this.state.token}/${this.state.lang_check}`,
      state: { item: item },
    });
  };

  delete = async () => {
    var url = window.location.href;
    let token = url.split("/");
    const apiUrls = this.state.setToyou.Page === "address" ? `${apiUrl}/customerAddress/${this.state.item_data.id}` : `${apiUrl}/toyou/siteAddress/${this.state.item_data.id} `;
    const result = await axios({
      method: "delete",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token[4]} `,
      },
    }).then(async (res) => {
      var url = window.location.href;
      let token = url.split("/");
      this.getAddress(token[4]);
      this.setState({ openCheck: false });
    });
  };

  handleOpen = () => {
    this.setState({ openCheck: true });
  };
  handleClose = () => {
    this.setState({ openCheck: false });
  };
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };
  render() {
    const { data, addressData, shippingAddressData, openCheck } = this.state;

    return (
      <div
        className="container"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <div className="col">
              <span
                style={{
                  fontSize: "26px",
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    } `,
                }}
              >
                {this.state.language.length > 0
                  ? this.getLanguage(1010216)
                  : "ที่อยู่"}
              </span>
            </div>
            <div className="col">
              <span
                style={{
                  fontSize: "18px",
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    } `,
                  color: "rgba(72, 72, 72, 1)",
                }}
              >
                {this.state.language.length > 0
                  ? this.getLanguage(1010301)
                  : "ที่อยู่สำหรับการจัดส่ง"}
              </span>
            </div>
          </div>

          <div>
            <button
              style={{
                fontSize: "18px",
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Bd"
                  : "Delivery_A"
                  } `,
              }}
              className="nav-link add"
              onClick={() =>
                this.props.history.push(
                  `/${this.state.setToyou.CreateAddress}/${this.state.token}/${this.state.lang_check}`
                )
              }
            >
              {this.state.language.length > 0
                ? this.getLanguage(1010302)
                : "ที่อยู่สำหรับการจัดส่ง"}
            </button>
          </div>
        </div>

        <div>
          {addressData.map((item, key) => {
            return (
              <div
                key={key}
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div className="card card-body ml-3">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title"
                      style={{
                        fontSize: "22px",
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Bd"
                          : "Delivery_A"
                          } `,
                      }}
                    >
                      {item.location_name}
                    </h5>
                    <div>
                      <span className="btn" onClick={() => this.Edit(item)}>
                        <img
                          src={Icon_Edit}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                      <span
                        className="btn"
                        onClick={() =>
                          this.setState({
                            openCheck: true,
                            message:
                              this.state.language.length > 0
                                ? this.state.language[27].content
                                : null,
                            item_data: item,
                          })
                        }
                      >
                        <img
                          src={Icon_Delete}
                          style={{ height: "20px", width: "15px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "22px",
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        } `,
                    }}
                  >
                    {item.address1} <br />
                    {item.address2} <br />
                    {item.address3} <br />
                    {item.location_name} <br />
                    {item.province
                      ? this.state.lang_check.toLowerCase() === "en"
                        ? item.province.name_en
                        : item.province.name_th
                      : null}{" "}
                    {item.post_code}
                    <br />
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row mt-3 pl-3">
          <div className="col">
            <button
              className="backaddress"
              style={{
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Bd"
                  : "Delivery_A"
                  } `,
                fontSize: "18px",
              }}
              onClick={() =>
                this.props.history.push(
                  `/${this.state.setToyou.privilege}/${this.state.token}/${this.state.lang_check}`
                )
              }
            >
              {this.state.language.length > 0
                ? this.getLanguage(1010304)
                : "Back to home"}
            </button>
          </div>

          <div className="col"></div>
        </div>

        <Modal
          open={openCheck}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center">
                  <span style={{ fontSize: "30px" }}>{this.state.message}</span>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  <svg
                    width="5em"
                    height="5em"
                    viewBox="0 0 16 16"
                    className="bi bi-question-circle-fill text-info"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"
                    />
                  </svg>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.delete()}
                >
                  {this.state.language.length > 0
                    ? this.state.language[28].content
                    : null}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.handleClose()}
                >
                  {this.state.language.length > 0
                    ? this.state.language[29].content
                    : null}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Address;
