import React, { Component } from "react";
import Icon_Points from "../../assets/icon/Icon_Points.png";
import axios from "axios";
import { apiUrl, COLOR_PRIMARY, COLOR_SECONDARY } from "../../constants";
import Modal from "@material-ui/core/Modal";
import { withRouter } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Nav,
  DropdownButton,
  Image,
  Dropdown,
} from "react-bootstrap";

import "./MyPoint.css";
class MyPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_privilege: null,
      is_delivery: null,
      data: {},
      open: false,
      openCheck: false,
      openSeccess: false,
      message: "",
      send: "",
      end: "",
      lang_check: "",
      dateExp: "",
      language: [],
      setToyou: {
        Page: "",
        privilege: "",
        MyPoint: "",
        Reward: "",
        address: "",
      },
    };
  }

  componentDidMount() {
    this.setState({ token: "" });
    var url = window.location.href;
    let token = url.split("/");
    let lang = url.split("/");
    localStorage.setItem("lang_check", JSON.stringify(lang[5]));

    const path = window.location.pathname
    var n = path.search("MyPointToyou");
    var check_page = Number(n) > 0 ? "MyPointToyou" : "MyPoint"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          Page: "MyPointToyou",
          privilege: "accountCustomer",
          MyPoint: "MyPointToyou",
          Reward: "RewardToyou",
          address: "addressToyou",
        }
      });
    } else {
      this.setState({
        setToyou: {
          Page: "MyPoint",
          privilege: "privilege",
          MyPoint: "MyPoint",
          Reward: "Reward",
          address: "address",
        }
      });
    }

    if (lang[5].toLowerCase() === "en" || lang[5].toLowerCase() === "th") {
      let langu = JSON.parse(localStorage.getItem(`lang_${lang[5].toLowerCase()}`));
      this.setState({ language: langu });
    } else if (lang[5] === null || lang[5] === undefined) {
      let langu = JSON.parse(localStorage.getItem("lang_th"));
      this.setState({ language: langu });
    }

    this.setState({ lang_check: lang[5] });
    this.getCustomers(token[4], lang[5], check_page);
    this.setState({ token: token[4] });

  }

  getPoint = async (token) => {
    const apiUrls = `${apiUrl}/account/getPoint`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        this.setState({
          point: res.data.point,
        });
      })
      .catch((err) => {
        this.props.history.push(`/Error/${this.state.lang_check}`);
      });
  };

  getPointNearExpire = async (token, check_page) => {
    const apiUrls = check_page === "MyPoint" ? `${apiUrl}/account/getPointNearExpire` : `${apiUrl}/site/getPointNearExpire`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        this.setState({
          dateExp: res.data.date ? res.data.date : res.data.expireDate ? res.data.expireDate : "",
          pointExp: res.data.point,
        });
      })
      .catch((err) => {
        this.props.history.push(`/Error/${this.state.lang_check}`);
      });
  };


  getCustomers = async (token, lang, check_page) => {
    if (check_page === "MyPoint") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.getPoint(token);
          this.getPointNearExpire(token, check_page);
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }

        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res.data.data)
          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });
          this.getPointNearExpire(token, check_page);

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {

            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });
    }
  };

  convert_time = (string_time) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    var timestamp = string_time.replace(/\/Date\((.*?)\)\//g, "$1");
    var date = new Date(timestamp);
    var month = date.getMonth();
    var day = date.getDate();
    var year = date.getFullYear();
    var h = date.getHours();
    var m = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

    return `${day} ${monthNames[date.getMonth()]} ${year}`;
  };

  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  setDegitSaraly = (point) => {
    if (point) {
      var parts = point.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let pointa = parts.join(".");
      return pointa;
    } else {
      return " ";
    }
  };
  render() {
    const { language } = this.state;

    return (
      <Container>
        <Nav className="justify-content-end mt-3 mb-3">
          <Nav.Item>
            <div
              style={{
                color: "rgba(166, 166, 166, 1)",
                paddingTop: "6px",
                paddingRight: "6px",
              }}
            >
              <img
                src={Icon_Points}
                style={{ height: "25px", width: "25px" }}
              />
              <span
                style={{
                  fontSize: `${this.state.lang_check.toLowerCase() === "th"
                    ? "25px"
                    : "18px"
                    }`,
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  color: "rgba(74, 74, 74, 1)",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {this.state.point
                  ? this.state.point
                  : 0}{" "}
                {language.length > 0 ? this.getLanguage(1010101) : "Point"}
              </span>
            </div>
          </Nav.Item>
          <Nav.Item>
            <DropdownButton
              title={
                <span
                  style={{
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Med"
                      : "Delivery_A"
                      }`,
                    fontSize: `${this.state.lang_check.toLowerCase() === "th"
                      ? "25px"
                      : "18px"
                      }`,
                    color: `${COLOR_SECONDARY}`,
                  }}
                >
                  {language.length > 0
                    ? this.getLanguage(1010102)
                    : "My Privilege"}
                </span>
              }
              id="basic-nav-dropdown"
              variant="outline"
              onSelect={this.handleSelect}
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderStyle: "solid",
                fontSize: `${this.state.lang_check.toLowerCase() === "th" ? "25px" : "18px"
                  }`,
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Med"
                  : "Delivery_A"
                  }`,
                borderColor: `${COLOR_SECONDARY}`,
                borderRadius: "5px",
              }}
            >
              <Dropdown.Item
                href={`/${this.state.setToyou.MyPoint}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0 ? this.getLanguage(1010114) : "My Points"}
              </Dropdown.Item>

              <Dropdown.Item
                href={`/${this.state.setToyou.Reward}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0 ? this.getLanguage(1010103) : "HISTORY"}
              </Dropdown.Item>
              <Dropdown.Item
                href={`/${this.state.setToyou.address}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0
                  ? this.getLanguage(1010104)
                  : "DELIVERY ADDRESS"}
              </Dropdown.Item>
            </DropdownButton>
          </Nav.Item>
        </Nav>

        <div className="accordion" id="accordionExample">
          <div className="card">
            <div
              className="card-header"
              style={{ backgroundColor: "#FFCC01" }}
              id="headingOne"
            >
              <h2 className="mb-0">
                <div className="d-flex justify-content-between">
                  <a
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {language.length > 0 ? (
                      <span style={{ color: "#000" }}>
                        {this.getLanguage(1010907)}
                      </span>
                    ) : (
                      "M Point"
                    )}
                  </a>
                  <a
                    style={{
                      color: "#3b4fbc",

                      textAlign: "right",
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {this.state.point ? (
                      <span style={{ color: "#000" }}>
                        {this.state.point}
                      </span>
                    ) : (
                      0
                    )}{" "}
                    {language.length > 0 ? (
                      <span style={{ color: "#000" }}>
                        {this.getLanguage(1010101)}
                      </span>
                    ) : (
                      "Point"
                    )}
                  </a>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <span
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {language.length > 0
                      ? this.getLanguage(1010902)
                      : "Available points "}{" "}

                  </span>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <a
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {/* Redeem M Point for instant */}
                  </a>
                  {/* <a
                    className="btn btn-link "
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-controls="collapseOne"
                    style={{
                      color: "#000",

                      fontFamily: `${
                        this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Med"
                          : "Delivery_A"
                      }`,
                      fontSize: `${
                        this.state.lang_check.toLowerCase() === "th"
                          ? "25px"
                          : "18px"
                      }`,
                    }}
                  >
                    View Detail
                  </a> */}
                </div>
              </h2>
            </div>

            {/* <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <span
                  style={{
                    fontSize: `${
                      this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                    }`,
                    fontFamily: `${
                      this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                    }`,
                  }}
                >
                  {language.length > 0
                    ? this.getLanguage(1010901)
                    : "Your Total Points"}{" "}
                  {Number(this.state.point) > 0 ? (
                    <span style={{ color: "#000" }}>
                      {this.setDegitSaraly(this.state.point)}
                    </span>
                  ) : (
                    0
                  )}{" "}
                  {language.length > 0 ? this.getLanguage(1010101) : "Point"}
                </span>

                <br />
                <span
                  style={{
                    color: "#000",
                    fontSize: `${
                      this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                    }`,
                    fontFamily: `${
                      this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                    }`,
                  }}
                >
                  {language.length > 0
                    ? this.getLanguage(1010902)
                    : "Available points "}{" "}
                  {Number(this.state.point) > 0 ? (
                    <span style={{ color: "#000" }}>
                      {this.setDegitSaraly(this.state.point)}
                    </span>
                  ) : (
                    0
                  )}{" "}
                  {language.length > 0 ? this.getLanguage(1010101) : "Point"}
                </span>
                <br />
                <br />
                <div
                  style={{
                    fontSize: `${
                      this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                    }`,
                    fontFamily: `${
                      this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                    }`,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      language.length > 0
                        ? this.getLanguage(1010905)
                        : "Term & Conditions",
                  }}
                />
              </div>
            </div>
         */}
          </div>
          <div className="card">
            <div
              className="card-header"
              style={{ backgroundColor: "#FFCC01" }}
              id="headingOne"
            >
              <h2 className="mb-0">
                <div className="d-flex justify-content-between">
                  <a
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {language.length > 0 ? (
                      <span style={{ color: "#000" }}>
                        {this.getLanguage(1010908)}
                      </span>
                    ) : (
                      "Expiring"
                    )}
                  </a>
                  <a
                    style={{
                      color: "#3b4fbc",

                      textAlign: "right",
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >

                    {this.state.setToyou.Page === "MyPointToyou" ?
                      <span style={{ color: "#000" }}>
                        {this.state.dateExp
                          ? this.convert_time(this.state.dateExp)
                          : null}
                      </span>
                      : <>{this.state.pointExp ? (
                        <span style={{ color: "#000" }}>
                          {this.state.pointExp}
                        </span>
                      ) : (
                        0
                      )}
                        {" "}
                        {language.length > 0 ? (
                          <span style={{ color: "#000" }}>
                            {this.getLanguage(1010101)}
                          </span>
                        ) : (
                          "Point"
                        )}
                      </>
                    }


                  </a>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <span
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {this.state.setToyou.Page === "MyPoint" ?
                      <>
                        {language.length > 0
                          ? this.getLanguage(1010904)
                          : "Point will be expired on"}{" "}
                        {this.state.dateExp
                          ? this.convert_time(this.state.dateExp)
                          : null}
                      </>
                      : null}
                  </span>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <a
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {/* Redeem M Point for instant */}
                  </a>
                  <a
                    className="btn btn-link "
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    style={{
                      color: "#000",

                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: `${this.state.lang_check.toLowerCase() === "th"
                        ? "25px"
                        : "18px"
                        }`,
                    }}
                  >
                    {language.length > 0
                      ? this.getLanguage(1010906)
                      : "View Detail"}{" "}
                  </a>
                </div>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">

                <br />
                <div
                  style={{
                    fontSize: `${this.state.lang_check.toLowerCase() === "th"
                      ? "25px"
                      : "18px"
                      }`,
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Med"
                      : "Delivery_A"
                      }`,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      language.length > 0
                        ? this.getLanguage(1010905)
                        : "Terms & Conditions",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          className="backDetail mb-2"
          style={{
            fontFamily: `${this.state.lang_check.toLowerCase() === "th"
              ? "DB_Heavent_Bd"
              : "Delivery_A"
              }`,
            fontSize: "18px",
            background: `${COLOR_SECONDARY}`,
            marginTop: "20px",
          }}
          onClick={() =>
            this.props.history.push(
              `/${this.state.setToyou.privilege}/${this.state.token}/${this.state.lang_check}`
            )
          }
        >
          {this.state.language.length > 0
            ? this.getLanguage(1010304)
            : "Back to home"}
        </button>
      </Container>
    );
  }
}

export default MyPoint;
