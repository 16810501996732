import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";
import Modal from "@material-ui/core/Modal";
import "./Address.css";
import Swal from "sweetalert2";

class CreateAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      addressData: [],
      eng_num_validate: new RegExp(/^[a-zA-Z0-9 -/]+$/),
      num_validate: new RegExp(/^[0-9\b]+$/),

      id: "",
      first_name: "",
      last_name: "",
      location_name: "",
      address1: "",
      address2: "",
      address3: "",
      phone: "",
      post_code: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",

      postalCode: "",
      checkbox: false,
      shippingAddressData: -1,
      token: "",
      open: false,
      message: "",
      lang_check: "",
      language: [],
      locations: [],
      locations_district: [],
      sub_district: [],
      district: [],
      province: [],
      province_select: {},
      districtData: {},
      sub_district_selete: [],
      codeD: "",
      setToyou: {
        address: "",
        mode: "",
      },
    };
  }

  componentDidMount() {
    const path = window.location.pathname
    var n_create = path.search("CreateAddressToyou");
    var n_edit = path.search("EditAddressToyou");

    var mode_create = path.search("CreateAddress" || "CreateAddressToyou");

    var check_page_create = Number(n_create) > 0 ? "addressToyou" : "address"
    var check_page_edit = Number(n_edit) > 0 ? "addressToyou" : "address"
    var mode = Number(mode_create) > 0 ? "Create" : "Edit"
    if (mode === "Create") {
      if (Number(n_create) > 0) {
        this.setState({
          setToyou: {
            address: "addressToyou",
            mode: "create",
          }
        });
      } else {
        this.setState({
          setToyou: {
            address: "address",
            mode: "create",
          }
        });
      }

      var url = window.location.href;
      let token = url.split("/");
      let lang = url.split("/");
      let langu = JSON.parse(localStorage.getItem("lang_th"));
      this.setState({ language: langu });
      this.setState({ lang_check: lang[5] });

      this.getCustomers(token[4], lang[5], check_page_create);
      this.setState({ token: token[4] });
    }

    if (mode === "Edit") {
      if (Number(n_edit) > 0) {
        this.setState({
          setToyou: {
            address: "addressToyou",
            mode: "edit",
          }
        });
      } else {
        this.setState({
          setToyou: {
            address: "address",
            mode: "edit",
          }
        });
      }

      var url = window.location.href;
      let token = url.split("/");
      let lang = url.split("/");
      let langu = JSON.parse(localStorage.getItem("lang_th"));
      this.setState({ language: langu });
      this.setState({ lang_check: lang[5] });

      this.getCustomers(token[4], lang[5], check_page_edit);
      this.setState({ token: token[4] });
      this.setState({
        id: this.props.location.state.item.id,
        first_name: this.props.location.state.item.first_name,
        last_name: this.props.location.state.item.last_name,
        location_name: this.props.location.state.item.location_name,
        address1: this.props.location.state.item.address1,
        address2: this.props.location.state.item.address2,
        address3: this.props.location.state.item.address3,
        phone: this.props.location.state.item.phone,
        post_code: this.props.location.state.item.post_code,
        checkbox: this.props.location.state.item.is_save,
        province_id: this.props.location.state.item.province_id,
        district_id: this.props.location.state.item.district_id,
        sub_district_id: this.props.location.state.item.sub_district_id,
      });
      this.getLocation(this.props.location.state.item.post_code)
      this.getDistricts(this.props.location.state.item.province_id)

      const apiUrls = `${apiUrl}/location/zipcode/${this.props.location.state.item.post_code}`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        var dis_data = [];
        if (res.data.length > 0) {
          res.data.map((data, key) => {
            if (data.province_id === this.props.location.state.item.province_id) {
              dis_data.push({
                code: data.code,
                id: data.id,
                name_en: data.name_en,
                name_th: data.name_th,
                province_id: data.province_id,
                subDistricts: data.subDistricts
              });
            }
          });

          this.setState({
            districtData: dis_data.length === 1 ? dis_data[0] : {},
            locations: dis_data,
            locations_district: dis_data
          });
          if (dis_data.length === 1) {
            this.setState({
              district: dis_data,
            });
          }

          let item = dis_data.find((l) => Number(l.id) === Number(this.props.location.state.item.district_id));
          this.setState({
            sub_district_selete: item.subDistricts,
          });
        }
      })


    }

  }

  AlertError = (message) => {
    Swal.fire("Address !", message, "error");
  };

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "address") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {

          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });
    }
  };

  getLocation(postcode) {
    const apiUrls = `${apiUrl}/location/zipcode/${postcode}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      var province_data = [];
      if (res.data.length > 0) {
        res.data.map((data, key) => {
          let check_province = province_data.find((p) => Number(p.id) === Number(data.province.id));
          if (!check_province) {
            province_data.push(data.province);
          }
        });
        this.setState({ province: province_data })
        if (province_data.length === 1) {
          this.getDistricts(province_data[0].id)
        }
      }
    })
  }

  getDistricts(id_pro) {
    const apiUrls = `${apiUrl}/location/zipcode/${this.state.post_code}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      var dis_data = [];
      if (res.data.length > 0) {
        res.data.map((data, key) => {
          if (data.province_id === id_pro) {
            dis_data.push({
              code: data.code,
              id: data.id,
              name_en: data.name_en,
              name_th: data.name_th,
              province_id: data.province_id,
              subDistricts: data.subDistricts
            });
          }
        });
        this.setState({
          districtData: dis_data.length === 1 ? dis_data[0] : {},
          locations: dis_data,
          locations_district: dis_data,
          province_id: id_pro
        });
        if (dis_data.length === 1) {
          this.setState({
            district: dis_data,
            district_id: dis_data[0].id,
            sub_district_selete: dis_data[0].subDistricts,
          });
          if (dis_data[0].subDistricts.length === 1) {
            this.setState({ sub_district_id: dis_data[0].subDistricts[0].id })
          }
        }
      }
    })
  }

  createAddress = async () => {
    var url = window.location.href;
    let token = url.split("/");

    if (!this.state.first_name) {
      return this.AlertError("Please insert first name !!");
    }
    if (!this.state.last_name) {
      return this.AlertError("Please insert last name !!");
    }
    if (!this.state.location_name) {
      return this.AlertError("Please insert location name !!");
    }
    if (this.state.location_name) {
      if (!this.state.eng_num_validate.test(this.state.location_name)) {
        return this.AlertError("Location name must be english and number only.");
      }
    }
    if (!this.state.address1) {
      return this.AlertError("Please insert address1 !!");
    }
    if (this.state.address1) {
      if (!this.state.eng_num_validate.test(this.state.address1)) {
        return this.AlertError("Address 1 must be english and number only.");
      }
    }
    if (this.state.address2) {
      if (!this.state.eng_num_validate.test(this.state.address2)) {
        return this.AlertError("Address 2 must be english and number only.");
      }
    }
    if (this.state.address3) {
      if (!this.state.eng_num_validate.test(this.state.address3)) {
        return this.AlertError("Address 3 must be english and number only.");
      }
    }

    if (!this.state.phone) {
      return this.AlertError("Please insert phone !!");
    }
    if (this.state.phone) {
      if (this.state.phone.length < 9) {
        return this.AlertError(this.state.language.length > 0
          ? this.getLanguage(1010422)
          : "");
      }
      if (!this.state.num_validate.test(this.state.phone)) {
        return this.AlertError(this.state.language.length > 0
          ? this.getLanguage(1010416)
          : "");
      }
    }
    if (!this.state.post_code) {
      return this.AlertError("Please insert post code !!");
    }
    if (this.state.post_code) {
      if (!this.state.num_validate.test(this.state.post_code)) {
        return this.AlertError(
          this.state.language.length > 0
            ? this.getLanguage(1010423)
            : ""
        );
      }
    }

    if (!this.state.province_id) {
      return this.AlertError("Please select province !!");
    }
    if (!this.state.district_id) {
      return this.AlertError("Please select district !!");
    }
    if (!this.state.sub_district_id) {
      return this.AlertError("Please select sub district !!");
    }

    if (
      this.state.first_name,
      this.state.last_name,
      this.state.location_name,
      this.state.address1,
      this.state.address2,
      this.state.address3,
      this.state.phone,
      this.state.post_code,
      this.state.province_id,
      this.state.district_id,
      this.state.sub_district_id
    ) {

      const apiUrls = this.state.setToyou.address === "address" ? `${apiUrl}/customerAddress/` : `${apiUrl}/toyou/siteAddress`;
      const result = await axios({
        method: "post",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token[4]}`,
        },
        data: {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          location_name: this.state.location_name,
          address1: this.state.address1,
          address2: this.state.address2 === "" ? " " : this.state.address2,
          address3: this.state.address3 === "" ? " " : this.state.address3,
          phone: this.state.phone,
          post_code: this.state.post_code,
          province: this.state.province_select,
          district: this.state.district,
          sub_district: this.state.sub_district,
          province_id: Number(this.state.province_id),
          district_id: this.state.district_id,
          sub_district_id: this.state.sub_district_id,
          is_save: true,
        },
      })
        .then(async (res) => {
          this.props.history.push(
            `/${this.state.setToyou.address}/${token[4]}/${this.state.lang_check}`
          );
        })
        .catch((err) => {
          if (err.response) {
            this.setState({
              open: true,
              message: err.response.data.message,
            });
          }
        });
    }
  };

  editAddress = async () => {
    var url = window.location.href;
    let token = url.split("/");

    if (!this.state.first_name) {
      return this.AlertError("Please insert first name !!");
    }
    if (!this.state.last_name) {
      return this.AlertError("Please insert last name !!");
    }
    if (!this.state.location_name) {
      return this.AlertError("Please insert location name !!");
    }
    if (this.state.location_name) {
      if (!this.state.eng_num_validate.test(this.state.location_name)) {
        return this.AlertError("Location name must be english and number only.");
      }
    }
    if (!this.state.address1) {
      return this.AlertError("Please insert address1 !!");
    }
    if (this.state.address1) {
      if (!this.state.eng_num_validate.test(this.state.address1)) {
        return this.AlertError("Address 1 must be english and number only.");
      }
    }
    if (this.state.address2) {
      if (!this.state.eng_num_validate.test(this.state.address2)) {
        return this.AlertError("Address 2 must be english and number only.");
      }
    }
    if (this.state.address3) {
      if (!this.state.eng_num_validate.test(this.state.address3)) {
        return this.AlertError("Address 3 must be english and number only.");
      }
    }

    if (!this.state.phone) {
      return this.AlertError("Please insert phone !!");
    }
    if (this.state.phone) {
      if (this.state.phone.length < 9) {
        return this.AlertError(this.state.language.length > 0
          ? this.getLanguage(1010422)
          : "");
      }
      if (!this.state.num_validate.test(this.state.phone)) {
        return this.AlertError(this.state.language.length > 0
          ? this.getLanguage(1010416)
          : "");
      }
    }
    if (!this.state.post_code) {
      return this.AlertError("Please insert post code !!");
    }
    if (this.state.post_code) {
      if (!this.state.num_validate.test(this.state.post_code)) {
        return this.AlertError(
          this.state.language.length > 0
            ? this.getLanguage(1010423)
            : ""
        );
      }
    }

    if (!this.state.province_id) {
      return this.AlertError("Please select province !!");
    }
    if (!this.state.district_id) {
      return this.AlertError("Please select district !!");
    }
    if (!this.state.sub_district_id) {
      return this.AlertError("Please select sub district !!");
    }

    if (
      this.state.first_name,
      this.state.last_name,
      this.state.location_name,
      this.state.address1,
      this.state.address2,
      this.state.address3,
      this.state.phone,
      this.state.post_code,
      this.state.province_id,
      this.state.district_id,
      this.state.sub_district_id
    ) {

      const apiUrls = this.state.setToyou.address === "address" ? `${apiUrl}/customerAddress/${this.state.id}` : `${apiUrl}/toyou/siteAddress/${this.state.id}`;
      const result = await axios({
        method: "put",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token[4]}`,
        },
        data: {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          location_name: this.state.location_name,
          address1: this.state.address1,
          address2: this.state.address2 === "" ? " " : this.state.address2,
          address3: this.state.address3 === "" ? " " : this.state.address3,
          phone: this.state.phone,
          post_code: this.state.post_code,
          province: this.state.province_select,
          district: this.state.district,
          sub_district: this.state.sub_district,
          province_id: Number(this.state.province_id),
          district_id: this.state.district_id,
          sub_district_id: this.state.sub_district_id,
          is_save: true,
        },
      })
        .then(async (res) => {
          this.props.history.push(
            `/${this.state.setToyou.address}/${token[4]}/${this.state.lang_check}`
          );
        })
        .catch((err) => {
          if (err.response) {
            this.setState({
              open: true,
              message: err.response.data.message,
            });
          }
        });
    }
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  handleProvinceChange(province_id) {
    if (province_id) {
      this.setState({ province_id: province_id })
      this.getDistricts(Number(province_id))
    }
  }

  getSubDistricts(district_id) {
    if (this.state.locations.length > 0) {
      let item = this.state.locations.find((l) => Number(l.id) === Number(district_id));
      this.setState({
        district: item,
        district_id: district_id,
        sub_district_selete: item.subDistricts,
      });
      if (item.subDistricts.length === 1) {
        this.setState({ sub_district_id: item.subDistricts[0].id })
      }
    }
  }

  handleSubDistricts(sub_district_id) {
    if (sub_district_id) {
      this.setState({ sub_district_id: sub_district_id })
    }
  }

  render() {
    const { data, addressData, shippingAddressData, open } = this.state;
    return (
      <div
        className="container"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="col">
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    }`,
                  fontSize: "26px",
                }}
              >
                {this.state.language.length > 0
                  ? this.state.language[this.state.setToyou.mode === "create" ? 49 : 48].content
                  : null}
              </span>
            </div>
            <div className="col">
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  fontSize: "22px",
                  color: "rgba(72, 72, 72, 1)",
                }}
              >
                {this.state.language.length > 0
                  ? this.state.language[50].content
                  : null}
              </span>

              <div
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                }}
              >
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <input
                      type="text"
                      name="first_name"
                      value={this.state.first_name}
                      onChange={(e) => {
                        this.setState({ first_name: e.target.value ? e.target.value : undefined })
                      }}
                      className="form-control"
                      placeholder={
                        this.state.language.length > 0
                          ? this.getLanguage(1010405)
                          : "First Name"
                      }
                    />
                    {this.state.first_name === undefined ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010414)
                          : ""}</div>
                      : ""
                    }
                  </div>

                  <div className="col">
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      value={this.state.last_name}
                      onChange={(e) => {
                        this.setState({ last_name: e.target.value ? e.target.value : undefined })
                      }}
                      placeholder={
                        this.state.language.length > 0
                          ? this.getLanguage(1010406)
                          : "Last Name"
                      }
                    />
                    {this.state.last_name === undefined ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010415)
                          : ""}</div>
                      : ""
                    }
                  </div>
                </div>

                <input
                  type="text"
                  name="location_name"
                  className="form-control"
                  value={this.state.location_name}
                  onChange={(e) => {
                    this.setState({ location_name: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010401)
                      : "Location Name"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.location_name === undefined ?
                  <div style={{ color: "red" }}>{
                    this.state.language.length > 0
                      ? this.getLanguage(1010412)
                      : ""}</div>
                  : ""
                }
                {this.state.location_name ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.location_name) ?
                      <div style={{ color: "red" }}>location name must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="text"
                  name="address1"
                  className="form-control"
                  value={this.state.address1}
                  onChange={(e) => {
                    this.setState({ address1: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010402)
                      : "Address 1"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.address1 === undefined ?
                  <div style={{ color: "red" }}>{
                    this.state.language.length > 0
                      ? this.getLanguage(1010413)
                      : ""}</div>
                  : ""
                }
                {this.state.address1 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.address1) ?
                      <div style={{ color: "red" }}>Address1 must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="text"
                  name="address2"
                  className="form-control"
                  value={this.state.address2}
                  onChange={(e) => {
                    this.setState({ address2: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010403)
                      : "Address 2"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.address2 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.address2) ?
                      <div style={{ color: "red" }}>Address 2 must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="text"
                  name="address3"
                  className="form-control"
                  value={this.state.address3}
                  onChange={(e) => {
                    this.setState({ address3: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010404)
                      : "Address 3"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.address3 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.address3) ?
                      <div style={{ color: "red" }}>Address 3 must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="tal"
                  id="phone"
                  name="phone"
                  maxLength="10"
                  value={this.state.phone}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value ? e.target.value : undefined })
                  }}
                  className="form-control small-input no-arrow"
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010407)
                      : "Phone"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />

                {this.state.phone === undefined ?
                  <div style={{ color: "red" }}>{
                    this.state.language.length > 0
                      ? this.getLanguage(1010416)
                      : ""}</div>
                  : ""
                }
                {this.state.phone ?
                  <>
                    {this.state.phone.length < 9 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010422)
                          : ""}</div>
                      : ""
                    }
                    {!this.state.num_validate.test(this.state.phone) ?
                      <div style={{ color: "red" }}>{this.state.language.length > 0
                        ? this.getLanguage(1010416)
                        : ""}</div>
                      : ""}
                  </>
                  : ""
                }

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <input
                      type="text"
                      name="post_code"
                      maxLength="5"
                      value={this.state.post_code}
                      onChange={(e) => {
                        this.setState({ post_code: e.target.value ? e.target.value : undefined })
                        if (Number(e.target.value.length) === 5) {
                          this.getLocation(e.target.value);
                        }
                        if (e.target.value.length != 5) {
                          this.setState({
                            locations: [],
                            locations_district: [],
                            sub_district: [],
                            district: [],
                            province: [],
                            province_id: "",
                            province_select: {},
                            districtData: {},
                            district_id: "",
                            sub_district_selete: [],
                            sub_district_id: "",
                          });
                        }
                      }}
                      placeholder={
                        this.state.language.length > 0
                          ? this.getLanguage(1010409)
                          : "Post code"
                      }
                      className="form-control no-arrow"
                    />
                    {this.state.post_code === undefined ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010423)
                          : ""}</div>
                      : ""
                    }
                    {this.state.post_code ?
                      <>
                        {!this.state.num_validate.test(this.state.post_code) ?
                          <div style={{ color: "red" }}>{this.state.language.length > 0
                            ? this.getLanguage(1010423)
                            : ""}</div>
                          : ""}
                      </>
                      : ""
                    }
                  </div>

                  <div className="col">
                    {this.state.province.length > 0 ? (
                      <select
                        name="province"
                        component="select"
                        className="form-control"
                        value={this.state.province_id}
                        onChange={(e) => this.handleProvinceChange(e.target.value)}
                      >

                        {this.state.province.length > 1 ? (
                          <>
                            <option value="">
                              {this.state.language.length > 0
                                ? this.getLanguage(1010408)
                                : "Select Province"}
                            </option>
                            {this.state.province.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {this.state.province.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            })}
                          </>
                        )}

                      </select>
                    ) : (
                      <select
                        name="province"
                        component="select"
                        className="form-control"
                      >
                        <option value="">
                          {this.state.language.length > 0
                            ? this.getLanguage(1010408)
                            : "Select Province"}
                        </option>
                      </select>
                    )}
                    {this.state.post_code === undefined && !this.state.province_id ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010430)
                          : ""}</div>
                      : ""
                    }
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <select
                      name="district"
                      component="select"
                      className="form-control"
                      value={this.state.district_id}
                      onChange={(e) => this.getSubDistricts(e.target.value)}
                    >
                      {this.state.locations.length === 1 ? (
                        <>
                          <option value={this.state.districtData.id}>
                            {this.state.lang_check.toLowerCase() === "en"
                              ? this.state.districtData.name_en
                              : this.state.districtData.name_th}
                          </option>
                        </>
                      ) : (
                        <>
                          <option value="">
                            {this.state.language.length > 0
                              ? this.getLanguage(1010426)
                              : "Please Select District"}
                          </option>

                          {this.state.locations_district.map(
                            (data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            }
                          )}
                        </>
                      )}
                    </select>

                    {this.state.post_code === undefined && !this.state.district_id ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010428)
                          : ""}</div>
                      : ""
                    }

                  </div>
                  <div className="col">
                    <select
                      name="sub_district"
                      component="select"
                      className="form-control"
                      value={this.state.sub_district_id}
                      onChange={(e) => this.handleSubDistricts(e.target.value)}

                    >
                      {this.state.sub_district_selete.length === 1 ?
                        <>
                          <option value={this.state.sub_district_selete[0].id}>
                            {this.state.lang_check.toLowerCase() === "en"
                              ? this.state.sub_district_selete[0].name_en
                              : this.state.sub_district_selete[0].name_th}
                          </option>
                        </>
                        :
                        <>
                          <option value="">
                            {this.state.language.length > 0
                              ? this.getLanguage(1010427)
                              : "Please select sub district"}
                          </option>
                          {this.state.sub_district_selete
                            ? this.state.sub_district_selete.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            })
                            : null}
                        </>
                      }
                    </select>

                    {this.state.post_code === undefined && !this.state.sub_district_id ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010429)
                          : ""}</div>
                      : ""
                    }

                  </div>
                </div>

                <div className="row " style={{ marginTop: "40px" }}>
                  <div className="col">
                    <button
                      style={{
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Bd"
                          : "Delivery_A"
                          }`,
                        fontSize: "18px",
                      }}
                      className="backaddress"
                      onClick={() =>
                        this.props.history.push(
                          `/${this.state.setToyou.address}/${this.state.token}/${this.state.lang_check}`
                        )
                      }
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010410)
                        : "Back to home"}
                    </button>
                  </div>
                  <div className="col">
                    {this.state.setToyou.mode === "create" ?
                      <button
                        style={{
                          fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                            ? "DB_Heavent_Bd"
                            : "Delivery_A"
                            }`,
                          fontSize: "18px",
                        }}
                        type="submit"
                        onClick={() => this.createAddress()}
                        className="backaddress"
                      >
                        {this.state.language.length > 0
                          ? this.getLanguage(1010411)
                          : "Save"}
                      </button>
                      :
                      <button
                        style={{
                          fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                            ? "DB_Heavent_Bd"
                            : "Delivery_A"
                            }`,
                          fontSize: "18px",
                        }}
                        type="submit"
                        onClick={() => this.editAddress()}
                        className="backaddress"
                      >
                        {this.state.language.length > 0
                          ? this.getLanguage(1010411)
                          : "Save"}
                      </button>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Modal open={open} disablePortal disableEnforceFocus disableAutoFocus>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center">
                  <svg
                    width="10em"
                    height="10em"
                    viewBox="0 0 16 16"
                    className="bi bi-x-circle-fill text-danger"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                    />
                  </svg>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  {this.state.message}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.handleClose()}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010506)
                    : "Close"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CreateAddress;
