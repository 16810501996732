import React, { Component } from "react";
import "./RewardRedemption.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { apiUrl } from "../../constants";
import Pagination2 from "@material-ui/lab/Pagination";

class RewardRedemption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      token: "",
      offset: 0,
      elements: [],
      perPage: 4,
      currentPage: 0,
      code: "",
      language: [],
      pageRe: 1,
      totalPageRe: 10,
      limitRe: 5,
      check_page: ""
    };
  }

  handlePageChangeRe = (event, value) => {
    this.setState({ pageRe: value });
    this.getRewardRedemption(value, this.state.limitRe);
  };

  handlePageSizeChangeRe = (event) => {
    this.setState({ limitRe: event.target.value, pageRe: 1 });
    this.getRewardRedemption(1, event.target.value);
  };

  componentDidMount() {
    const { lang_check } = this.state;
    var url = window.location.href;
    let lang = url.split("/");

    const path = window.location.pathname
    var n = path.search("RewardToyou");
    var check_page = Number(n) > 0 ? "RewardToyou" : "Reward"
    this.setState({ check_page: check_page });
    this.setState({ lang_check: lang[5] });

     if (lang[5].toLowerCase() === "en" || lang[5].toLowerCase() === "th") {
      let langu = JSON.parse(localStorage.getItem(`lang_${lang[5].toLowerCase()}`));
      this.setState({ language: langu });
    } else if (lang[5] === null || lang[5] === undefined) {
      let langu = JSON.parse(localStorage.getItem("lang_th"));
      this.setState({ language: langu });
    }

    localStorage.setItem("lang_check", JSON.stringify(lang[5]));

    let token = url.split("/");
    this.setState({ token: token[4] });

    this.getRewardRedemption(1, 5);
  }
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };
  getRewardRedemption = async (page, limit) => {

    const path = window.location.pathname
    var n = path.search("RewardToyou");
    var check_page = Number(n) > 0 ? "RewardToyou" : "reward"

    const apiUrls = check_page === "RewardToyou" ? `${apiUrl}/toyou/privilege/history?limit=${limit}&page=${page}` : `${apiUrl}/privilege/history?limit=${limit}&page=${page}`;
    var url = window.location.href;
    let token = url.split("/");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token[4]}`,
      },
    }).then((res) => {
      var date_all = []
      res.data.data.map((data, key) => {
        console.log(data)
      })
      var totalpage = Math.ceil(res.data.total / res.data.limit);
      this.setState({
        data: res.data.data,
        pageCount: Math.ceil(res.data.data.length / this.state.perPage),
        totalPageRe: totalpage

      });
    });
  };

  getRenderRows = () => {
    const { data, lang_check } = this.state;
    if (data.length > 0) {
      return data
        .map((item, key) => (
          <tr key={key}>
            <td>{item ? item.order_number : ""}</td>
            <td>
              {item.deal_image !== undefined ? (
                <img
                  src={item.deal_image}
                  style={{ height: "84px", width: "84px" }}
                />
              ) : null}
            </td>
            <td>{lang_check && lang_check.toLowerCase() === "en" ? item.deal_name : item.deal_name_th}</td>

            {item.is_delivery ?
              this.state.check_page === "RewardToyou" ?
                null :
                <td>
                </td>
              : (
                this.state.check_page === "RewardToyou" ?
                  null :
                  <td>
                    <a
                      href=""
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() => this.setState({ code: item.privilege_code })}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010217)
                        : "Detail"}
                    </a>
                  </td>
              )}

            <td>
              {item
                ? lang_check
                  ? lang_check.toLowerCase() === "en"
                    ? item.status_en
                    : item.status_th
                  : ""
                : ""}
            </td>
            <td>{item ? item.deal_point_txt : ""}</td>
            <td>{item.created_at}</td>


            {this.state.check_page === "RewardToyou" ?
              <td>
                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.location_name
                    ? item.toyouSiteAddress.location_name + " "
                    : ""
                  : null}
                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.address1
                    ? item.toyouSiteAddress.address1 + " "
                    : ""
                  : null}
                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.address2
                    ? item.toyouSiteAddress.address2 + " "
                    : null
                  : null}
                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.address3
                    ? item.toyouSiteAddress.address3 + " "
                    : ""
                  : null}

                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.province
                    ? lang_check.toLowerCase() === "en"
                      ? item.toyouSiteAddress.province.name_en + " "
                      : item.toyouSiteAddress.province.name_th + " "
                    : ""
                  : null}
                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.post_code
                    ? item.toyouSiteAddress.post_code + " "
                    : ""
                  : null}
                {item.toyouSiteAddress
                  ? item.toyouSiteAddress.phone
                    ? item.toyouSiteAddress.phone + " "
                    : ""
                  : null}
              </td>
              : <td>
                {item.customerAddress
                  ? item.customerAddress.location_name
                    ? item.customerAddress.location_name + " "
                    : ""
                  : null}
                {item.customerAddress
                  ? item.customerAddress.address1
                    ? item.customerAddress.address1 + " "
                    : ""
                  : null}
                {item.customerAddress
                  ? item.customerAddress.address2
                    ? item.customerAddress.address2 + " "
                    : null
                  : null}
                {item.customerAddress
                  ? item.customerAddress.address3
                    ? item.customerAddress.address3 + " "
                    : ""
                  : null}

                {item.customerAddress
                  ? item.customerAddress.province
                    ? lang_check.toLowerCase() === "en"
                      ? item.customerAddress.province.name_en + " "
                      : item.customerAddress.province.name_th + " "
                    : ""
                  : null}
                {item.customerAddress
                  ? item.customerAddress.post_code
                    ? item.customerAddress.post_code + " "
                    : ""
                  : null}
                {item.customerAddress
                  ? item.customerAddress.phone
                    ? item.customerAddress.phone + " "
                    : ""
                  : null}
              </td>}
          </tr>
        ));
    }
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset }, () => {
      this.getRewardRedemption();
    });
  };

  render() {
    return (
      <div className="table" style={{ marginTop: "50px" }}>
        <div>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "rgba(255, 204, 0)" }}>
              <tr>
                <th
                  style={{
                    width: "15%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010211)
                    : "Order number"}
                </th>
                <th
                  style={{
                    width: "10%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010212)
                    : "Picture"}
                </th>
                <th
                  style={{
                    width: "25%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010213)
                    : "Products (Items)	"}
                </th>

                {this.state.check_page === "RewardToyou" ?
                  null :
                  <th
                    style={{
                      width: "10%",
                      fontFamily: "DB_Heavent_Med",
                      fontSize: "20px",
                    }}
                  >
                    {this.state.language.length > 0
                      ? this.getLanguage(1010214)
                      : "View Code"}
                  </th>
                }


                <th
                  style={{
                    width: "11%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010218)
                    : "Status"}
                </th>
                <th
                  style={{
                    width: "10%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010215)
                    : "Use points"}
                </th>
                <th
                  style={{
                    width: "10%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010207)
                    : "Date"}
                </th>
                <th
                  style={{
                    width: "35%",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "20px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010216)
                    : "ADDRESS"}
                </th>
              </tr>
            </thead>
            <tbody>{this.getRenderRows()}</tbody>
          </table>
        </div>
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={this.handlePageSizeChangeRe} value={this.state.limitRe}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination2
                className="mt-3 "
                count={this.state.totalPageRe}
                page={this.state.pageRe}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={this.handlePageChangeRe}
              />
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  View Code
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{this.state.code}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RewardRedemption;
