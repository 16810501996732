import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Privilege from "./page/Privilege/Privilege";
import Detail from "./page/Detail/Detail";
import Shipping from "./page/Shipping/Shipping";
import Reward from "./page/Reward/Reward";
import Error from "./page/Error/Error";
import Unsubscribe from "./page/Unsubscribe/Unsubscribe";
import Address from "./page/Address/Address";
import CreateAddress from "./page/Address/CreateAddress";
import EditAddress from "./page/Address/EditAddress";
import MyPoint from "./page/MyPoint/MyPoint";

import 'bootstrap/dist/css/bootstrap.min.css';

// Protected Route
const SecuredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => (
        <Component {...props} />
      )
    }
  />
);

class App extends Component {
  redirectToPrivilege = () => {
    return <Redirect to="/Error/en" />;
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/privilege/:token/:lang" component={Privilege} />
            <SecuredRoute path="/Detail/:id/:token/:lang" component={Detail} />
            <SecuredRoute path="/MyPoint/:token/:lang" component={MyPoint} />
            <SecuredRoute path="/Shipping/:id/:token/:lang" component={Shipping} />
            <SecuredRoute path="/Reward/:token/:lang" component={Reward} />
            <SecuredRoute path="/Address/:token/:lang" component={Address} />
            <SecuredRoute path="/CreateAddress/:token/:lang" component={CreateAddress} />
            <SecuredRoute path="/EditAddress/:token/:lang" component={CreateAddress} />

            <Route path="/accountCustomer/:token/:lang" component={Privilege} />
            <SecuredRoute path="/DetailToyou/:id/:token/:lang" component={Detail} />
            <SecuredRoute path="/MyPointToyou/:token/:lang" component={MyPoint} />
            <SecuredRoute path="/ShippingToyou/:id/:token/:lang" component={Shipping} />
            <SecuredRoute path="/RewardToyou/:token/:lang" component={Reward} />
            <SecuredRoute path="/AddressToyou/:token/:lang" component={Address} />
            <SecuredRoute path="/CreateAddressToyou/:token/:lang" component={CreateAddress} />
            <SecuredRoute path="/EditAddressToyou/:token/:lang" component={CreateAddress} />
            <SecuredRoute path="/Unsubscribe/:lang" component={Unsubscribe} />
            <SecuredRoute path="/Error/:lang" component={Error} />

            <Route exact={true} path="/" component={this.redirectToPrivilege} />
            <Route exact={true} path="*" component={this.redirectToPrivilege} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
