import React, { Component } from "react";
import RewardHistory from "./RewardHistory";
import RewardRedemption from "./RewardRedemption";
import {
  Container,
  Col,
  Row,
  Nav,
  DropdownButton,
  Image,
  Dropdown,
} from "react-bootstrap";
import "./Reward.css";
import axios from "axios";
import Icon_Points from "../../assets/icon/Icon_Points.png";
import { apiUrl, COLOR_PRIMARY, COLOR_SECONDARY } from "../../constants";

class Reward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      toHistory: props.location.state ? props.location.state.toHistory : 0,
      lang_check: "",
      language: [],
      point: "",
      setToyou: {
        privilege: "",
        MyPoint: "",
        Reward: "",
        address: "",
      },
    };
  }

  componentDidMount() {
    const path = window.location.pathname
    var n = path.search("RewardToyou");
    var check_page = Number(n) > 0 ? "accountCustomer" : "privilege"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          privilege: "accountCustomer",
          MyPoint: "MyPointToyou",
          Reward: "RewardToyou",
          address: "addressToyou",
        }
      });
    } else {
      this.setState({
        setToyou: {
          privilege: "privilege",
          MyPoint: "MyPoint",
          Reward: "Reward",
          address: "address",
        }
      });
    }

    this.setState({ token: "" });
    var url = window.location.href;
    let token = url.split("/");
    let lang = url.split("/");
    localStorage.setItem("lang_check", JSON.stringify(lang[5]));

    let langu = JSON.parse(localStorage.getItem(`lang_${lang[5].toLowerCase()}`));
    this.setState({ language: langu });
    this.setState({ lang_check: lang[5] });
    this.getCustomers(token[4], lang[5], check_page);
    this.setState({ token: token[4] });
  }

  getPoint = async (token) => {
    const apiUrls = `${apiUrl}/account/getPoint`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        this.setState({
          point: res.data.point,
        });
      })
      .catch((err) => {
        this.props.history.push(`/Error/${this.state.lang_check}`);
      });
  };

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "privilege") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.getPoint(token);
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }

        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {

          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {

            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem(`lang_th`));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });
    }
  };

  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  setDegitSaraly = (point) => {
    if (point) {
      var parts = point.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let pointa = parts.join(".");
      return pointa;
    } else {
      return " ";
    }
  };

  render() {
    const { language } = this.state;
    return (
      <div
        className="container"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <Nav className="justify-content-end mt-3 mb-3">
          <Nav.Item>
            <div
              style={{
                color: "rgba(166, 166, 166, 1)",
                paddingTop: "6px",
                paddingRight: "6px",
              }}
            >
              <img
                src={Icon_Points}
                style={{ height: "25px", width: "25px" }}
              />
              <span
                style={{
                  fontSize: "18px",
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  color: "rgba(74, 74, 74, 1)",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {this.state.point
                  ? this.state.point
                  : 0}{" "}
                {language.length > 0 ? this.getLanguage(1010101) : "Point"}
              </span>
            </div>
          </Nav.Item>
          <Nav.Item>
            <DropdownButton
              title={
                <span
                  style={{
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Med"
                      : "Delivery_A"
                      }`,
                    fontSize: "18px",
                    color: `${COLOR_SECONDARY}`,
                  }}
                >
                  {language.length > 0
                    ? this.getLanguage(1010102)
                    : "My Privilege"}
                </span>
              }
              id="basic-nav-dropdown"
              variant="outline"
              onSelect={this.handleSelect}
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderStyle: "solid",
                fontSize: "18px",
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Med"
                  : "Delivery_A"
                  }`,
                borderColor: `${COLOR_SECONDARY}`,
                borderRadius: "5px",
              }}
            >
              <Dropdown.Item
                href={`/${this.state.setToyou.MyPoint}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0 ? this.getLanguage(1010114) : "My Points"}
              </Dropdown.Item>

              <Dropdown.Item
                href={`/${this.state.setToyou.Reward}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0 ? this.getLanguage(1010103) : "HISTORY"}
              </Dropdown.Item>
              <Dropdown.Item
                href={`/${this.state.setToyou.address}/${this.state.token}/${this.state.lang_check}`}
              >
                {language.length > 0
                  ? this.getLanguage(1010104)
                  : "DELIVERY ADDRESS"}
              </Dropdown.Item>
            </DropdownButton>
          </Nav.Item>
        </Nav>
        <div className="row">
          <div
            style={{
              marginTop: "50px",
              fontFamily: "DB_Heavent_Bd",
              fontSize: "40px",
              marginRight: "20px",
            }}
          >
            <span>
              {this.state.language.length > 0
                ? this.getLanguage(1010201)
                : "HISTORY"}
            </span>
          </div>
        </div>
        <div className="row">
          <div
            style={{
              fontFamily: "DB_Heavent_Med",
              fontSize: "30px",
              marginRight: "20px",
              color: "rgba(119, 119, 119, 1)",
            }}
          >
            {this.state.language.length > 0
              ? this.getLanguage(1010202)
              : "SHIPPING HISTORY AND REDEMPTION HISTORY"}
          </div>
        </div>

        <div
          className="d-flex justify-content-between row"
          style={{ marginTop: "34px" }}
        >
          <div>
            <ul
              id="pills-tab"
              role="tablist"
              className="nav nav-pills red"
              aria-orientation="vertical"
            >
              <li className={this.state.toHistory === 0 ? "active" : ""}>
                <a
                  className={
                    this.state.toHistory === 0 ? "nav-link active" : "nav-link"
                  }
                  id="pills-history-tab"
                  data-toggle="pill"
                  href="#pills-history"
                  role="tab"
                  aria-controls="pills-history"
                  aria-selected="true"
                  aria-expanded="true"
                  style={{
                    backgroundColor: "rgba(233, 233, 233, 1)",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "25px",
                    color: "rgba(119, 119, 119, 1)",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010203)
                    : "SHIPPING HISTORY"}
                </a>
              </li>
              <li className={this.state.toHistory === 1 ? "active" : ""}>
                <a
                  className={
                    this.state.toHistory === 1 ? "nav-link active" : "nav-link"
                  }
                  id="pills-redemption-tab"
                  data-toggle="pill"
                  href="#pills-redemption"
                  role="tab"
                  aria-controls="pills-redemption"
                  aria-selected="false"
                  aria-expanded="true"
                  style={{
                    backgroundColor: "rgba(233, 233, 233, 1)",
                    fontFamily: "DB_Heavent_Med",
                    fontSize: "25px",
                    color: "rgba(119, 119, 119, 1)",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010204)
                    : "REDEMPTION HISTORY"}
                </a>
              </li>
            </ul>
          </div>

          {/* <div>
            <select
              className="form-control"
              style={{ fontFamily: "Delivery App", fontSize: "14px" }}
            >
              <option value="SELECT DATE">SELECT DATE</option>
            </select>
          </div> */}
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className={
              this.state.toHistory === 0
                ? "tab-pane fade show active row"
                : "tab-pane fade row"
            }
            id="pills-history"
            role="tabpanel"
            aria-labelledby="pills-history-tab"
          >
            <RewardHistory />
          </div>
          <div
            className={
              this.state.toHistory === 1
                ? "tab-pane fade show active row"
                : "tab-pane fade row"
            }
            id="pills-redemption"
            role="tabpanel"
            aria-labelledby="pills-redemption-tab"
          >
            <RewardRedemption />
          </div>
        </div>
        <div className="row">
          <button
            className="backs"
            onClick={() =>
              this.props.history.push(
                `/${this.state.setToyou.privilege}/${this.state.token}/${this.state.lang_check}`
              )
            }
            style={{ fontFamily: "DB_Heavent_Med", fontSize: "25px" }}
          >
            {this.state.language.length > 0
              ? this.getLanguage(1010210)
              : "Back to home"}
          </button>
        </div>
      </div>
    );
  }
}

export default Reward;
