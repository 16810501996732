import React, { Component } from "react";
// import datas from "../../data_history.json";
import ReactPaginate from "react-paginate";

import axios from "axios";
import { apiUrl } from "../../constants";
import Pagination2 from "@material-ui/lab/Pagination";

import "./RewardHistory.css";
class RewardHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      token: "",
      offset: 0,
      elements: [],
      perPage: 6,
      currentPage: 0,
      language: [],
      lang_check: "",
      Page: "",
      pageRe: 1,
      totalPageRe: 10,
      limitRe: 5,
      check_page: ""
    };
  }

  handlePageChangeRe = (event, value) => {
    this.setState({ pageRe: value });
    this.getRewardHistory(value, this.state.limitRe);
  };

  handlePageSizeChangeRe = (event) => {
    this.setState({ limitRe: event.target.value, pageRe: 1 });
    this.getRewardHistory(1, event.target.value);
  };

  componentDidMount() {
    var url = window.location.href;
    let lang = url.split("/");
    let token = url.split("/");
    const path = window.location.pathname
    var n = path.search("RewardToyou");
    var check_page = Number(n) > 0 ? "RewardToyou" : "Reward"
    this.setState({ lang_check: lang[5] });

    this.getCustomers(token[4], lang[5], check_page)

    this.setState({ token: token[4], check_page: check_page });
    this.getRewardHistory(1, 5);
  }

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "Reward") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.getPoint(token);
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          // this.props.history.push(`/Error/${lang ? lang : "en"}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
              let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
              this.setState({ language: langu });
            } else if (lang === null || lang === undefined) {
              let langu = JSON.parse(localStorage.getItem("lang_th"));
              this.setState({ language: langu });
            }
          } else {
            this.props.history.push(`/Error/${this.state.lang_check}`);
          }
        })
    }
  };

  getRewardHistory = async (page, limit) => {
    const path = window.location.pathname
    var n = path.search("RewardToyou");
    var check_page = Number(n) > 0 ? "RewardToyou" : "reward"

    const apiUrls = check_page === "RewardToyou" ? `${apiUrl}/toyou/shippingHistory` : `${apiUrl}/rewardHistory?limit=10000`;
    var url = window.location.href;
    let token = url.split("/");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token[4]}`,
      },
    }).then((res) => {
      var totalpage = Math.ceil(res.data.total / res.data.limit);

      this.setState({
        data: check_page === "RewardToyou" ? res.data : res.data.data,
        pageCount: Math.ceil(check_page === "RewardToyou" ? res.data.length : res.data.data.length / this.state.perPage),
        Page: check_page,
        totalPageRe: totalpage

      });
    });
  };

  gitRenderRows = () => {
    const { data } = this.state;
    if (data.length > 0) {
      return data
        .map((item, key) => (
          this.state.Page === "reward" ?
            <tr key={key}>
              <td>{item.airway_bill ? item.airway_bill : ""}</td>
              <td>{item.amount_txt}</td>
              <td>{item.created_at}</td>
              <td>
                {item.receiverCountryCode
                  ? item.receiverCountryCode.descrption
                  : ""}
              </td>
              <td>{item.point ? item.point_txt : ""}</td>
            </tr>
            :
            <tr key={key}>
              <td>{item.siteId ? item.siteId : ""}</td>
              <td>{item.site_id ? item.site_id : ""}</td>
              <td>{item.totalAmount ? item.totalAmount : ""}</td>
              <td>{item.totalPoint ?
                Number(item.totalPoint) < 0 ? "0" : item.totalPoint
                : "0"}</td>

              <td>{item.campaignId ? item.campaignId : ""}</td>
              <td>{item.name ? item.name : ""}</td>
              <td>{item.startDate ? item.startDate : ""}</td>
              <td>{item.endDate ? item.endDate : ""}</td>
            </tr>
        ));
    }
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset }, () => {
      this.gitRenderRows();
    });
  };
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  render() {
    return (
      <div className="table" style={{ marginTop: "50px" }}>
        <div>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#ffcc00" }}>
              <tr>
                {this.state.Page === "reward" ?
                  <>
                    <th
                      style={{
                        width: "15%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010205)
                        : "Shipment number"}
                    </th>
                    <th
                      style={{
                        width: "15%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010206)
                        : "Amount (THB)"}
                    </th>
                    <th
                      style={{
                        width: "15%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010207)
                        : "Date"}
                    </th>
                    <th
                      style={{
                        width: "40%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010208)
                        : "Destination Country"}
                    </th>
                    <th
                      style={{
                        width: "15%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010209)
                        : "Get points"}
                    </th>
                  </>
                  :
                  <>
                    <th
                      style={{
                        width: "10%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011101)
                        : "System ID"}
                    </th>
                    <th
                      style={{
                        width: "5%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011102)
                        : "Site ID"}
                    </th>
                    <th
                      style={{
                        width: "15%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011103)
                        : "Total Amount"}
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011104)
                        : "Total Point"}
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011105)
                        : "Campaign ID"}
                    </th>
                    <th
                      style={{
                        width: "20%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011106)
                        : "Campaign Name"}
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011107)
                        : "Start Date"}
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontFamily: "DB_Heavent_Med",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1011108)
                        : "End Date"}
                    </th>
                  </>
                }
              </tr>
            </thead>
            <tbody>{this.gitRenderRows()}</tbody>
          </table>
        </div>
        {this.state.check_page === "RewardToyou" ? null :
          <div className=" col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="mt-3">
                  {"Items per Page: "}
                  <select onChange={this.handlePageSizeChangeRe} value={this.state.limitRe}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6" style={{ display: "flex", justifyContent: "flex-end" }}>

                <Pagination2
                  className="mt-3 "
                  count={this.state.totalPageRe}
                  page={this.state.pageRe}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={this.handlePageChangeRe}
                />

              </div>
            </div>
          </div>
        }
      </div >
    );
  }
}

export default RewardHistory;
