import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";
class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang_check: "",
      language: [],
    };
  }
  componentDidMount() {
    var url = window.location.href;
    let lang = url.split("/");

    let langu = JSON.parse(localStorage.getItem("lang_th"));
    if (
      lang[4].toLowerCase() === "en" ||
      lang[4].toLowerCase() === "th"
    ) {
      let langu = JSON.parse(localStorage.getItem(`lang_${lang[4].toLowerCase()}`));
      this.setState({ language: langu });
    } else if (langu === null || langu === undefined) {
      let langu = JSON.parse(localStorage.getItem("lang_th"));
      this.setState({ language: langu });
    }
  }

  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  render() {
    return (
      <div className="container" style={{ marginTop: "50px" }}>
        <div className="page-wrap d-flex flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <div className="mb-4 lead">
                  {this.state.language
                    ? this.getLanguage(1010801)
                    : "Session exoired"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
