import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";
import Modal from "@material-ui/core/Modal";
import Swal from "sweetalert2";

import Checkbox from '@material-ui/core/Checkbox';

import "./Shipping.css";

class Shipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_privilege: props.match.params.id,
      id_privilege: props.location.state.id_privilege,
      data: {},
      eng_num_validate: new RegExp(/^[a-zA-Z0-9 -/]+$/),
      num_validate: new RegExp(/^[0-9\b]+$/),
      addressData: [],
      locationName: "",
      firstName: "",
      lastName: "",
      address: "",
      apartment: "",
      address3: "",
      phone: "",
      postalCode: "",
      checkbox: false,
      shippingAddressData: -1,
      token: "",
      regexp: /^[0-9\b]+$/,
      openSeccess: false,
      open: false,
      message: "",
      lang_check: "",
      language: [],
      locations: [],
      locations_district: [],
      sub_district: [],
      district: [],
      district_id: "",
      province: [],
      districtData: {},
      province_id: "",
      province_select: {},
      check_on_click: false,
      sub_district_selete: [],
      sub_district_id: "",
      setToyou: {
        privilege: "",
        Reward: "",
      },
    };

    this.handleChangeShippingAddressData = this.handleChangeShippingAddressData.bind(
      this
    );
  }

  AlertError = (message) => {
    Swal.fire("Redeem !", message, "error");
  };

  componentDidMount() {
    const path = window.location.pathname
    var n = path.search("shippingToyou");
    var check_page = Number(n) > 0 ? "accountCustomer" : "privilege"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          privilege: "accountCustomer",
          Reward: "RewardToyou",
        }
      });
    } else {
      this.setState({
        setToyou: {
          privilege: "privilege",
          Reward: "Reward",
        }
      });
    }

    var url = window.location.href;
    let token = url.split("/");
    let lang = url.split("/");
    console.log(this.props.location.state)
    this.setState({ lang_check: lang[6] });
    this.setState({
      id_privilege: this.props.match.params.id,
      id_privilege: this.props.location.state.id_privilege,
      qty: this.props.location.state.qty ? this.props.location.state.qty : "",
      account_no: this.props.location.state.account_no ? this.props.location.state.account_no : "",
      data: {},
      addressData: [],
      locationName: "",
      firstName: "",
      lastName: "",
      address: "",
      apartment: "",
      address3: "",
      province: "",
      postalCode: "",
      checkbox: false,
      shippingAddressData: -1,
      token: token[5],
      check_on_click: false,
    });

    this.getCustomers(token[5], lang[6], check_page);
    this.setState({ token: token[5] });
    this.getPrivilegeDetail(token[5], this.state.id_privilege, check_page);
    this.getAddress(token[5], check_page);
  }

  handleSubmit = () => {
    this.setState({
      check_on_click: true,
    });
    if (Number(this.state.shippingAddressData) === -1) {
      this.createAddress();
    } else {
      this.createAddressSelect();
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  goTo = async () => {
    let url = window.location.href;
    let lang = url.split("/");
    this.handleClose();
    this.props.history.push({
      pathname: `/${this.state.setToyou.Reward}/${this.state.token}/${lang[6]}`,
      state: {
        toHistory: 1,
      },
    });
  };
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };
  getCustomers = async (token, lang, check_page) => {
    if (check_page === "privilege") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }

        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {

          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });
    }
  };

  getLocation(postcode) {
    const apiUrls = `${apiUrl}/location/zipcode/${postcode}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      console.log(res)
      var province_data = [];
      if (res.data.length > 0) {
        res.data.map((data, key) => {
          let check_province = province_data.find((p) => Number(p.id) === Number(data.province.id));
          if (!check_province) {
            province_data.push(data.province);
          }
        });
        this.setState({ province: province_data })
        if (province_data.length === 1) {
          this.getDistricts(province_data[0].id)
        }
      }
    })
  }

  getDistricts(id_pro) {
    const apiUrls = `${apiUrl}/location/zipcode/${this.state.postalCode}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      console.log(res)
      var dis_data = [];
      if (res.data.length > 0) {
        res.data.map((data, key) => {
          if (data.province_id === id_pro) {
            dis_data.push({
              code: data.code,
              id: data.id,
              name_en: data.name_en,
              name_th: data.name_th,
              province_id: data.province_id,
              subDistricts: data.subDistricts
            });
          }
        });
        this.setState({
          districtData: dis_data.length === 1 ? dis_data[0] : {},
          locations: dis_data,
          locations_district: dis_data,
          province_id: id_pro
        });
        if (dis_data.length === 1) {
          this.setState({
            district: dis_data,
            district_id: dis_data[0].id,
            sub_district_selete: dis_data[0].subDistricts,
          });
          if (dis_data[0].subDistricts.length === 1) {
            this.setState({ sub_district_id: dis_data[0].subDistricts[0].id })
          }
        }
      }
    })
  }

  getAddress = async (token, check_page) => {
    const apiUrls = check_page === "privilege" ? `${apiUrl}/customerAddress` : `${apiUrl}/toyou/siteAddress`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        this.setState({
          addressData: res.data,
        });
      })
      .catch((err) => { });
  };

  getPrivilegeDetail = async (token, id_privilege, check_page) => {
    const apiUrls = check_page === "privilege" ? `${apiUrl}/privilege/deal/${id_privilege}` : `${apiUrl}/toyou/privilege/deal/${id_privilege}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      this.setState({
        data: res.data,
      });
    });
  };

  createAddress = async () => {
    var url = window.location.href;
    let token = url.split("/");
    this.setState({
      check_on_click: false,
    });
    if (!this.state.firstName) {
      return this.AlertError("Please insert first name !!");
    }
    if (!this.state.lastName) {
      return this.AlertError("Please insert last name !!");
    }
    if (!this.state.locationName) {
      return this.AlertError("Please insert location name !!");
    }
    if (this.state.locationName) {
      if (!this.state.eng_num_validate.test(this.state.locationName)) {
        return this.AlertError("Location name must be english and number only.");
      }
    }
    if (!this.state.address) {
      return this.AlertError("Please insert address1 !!");
    }
    if (this.state.address) {
      if (!this.state.eng_num_validate.test(this.state.address)) {
        return this.AlertError("Address 1 must be english and number only.");
      }
    }
    if (this.state.apartment) {
      if (!this.state.eng_num_validate.test(this.state.apartment)) {
        return this.AlertError("Address 2 must be english and number only.");
      }
    }
    if (this.state.address3) {
      if (!this.state.eng_num_validate.test(this.state.address3)) {
        return this.AlertError("Address 3 must be english and number only.");
      }
    }

    if (!this.state.phone) {
      return this.AlertError("Please insert phone !!");
    }
    if (this.state.phone) {
      if (this.state.phone.length < 9) {
        return this.AlertError(this.state.language.length > 0
          ? this.getLanguage(1010422)
          : "");
      }
      if (!this.state.num_validate.test(this.state.phone)) {
        return this.AlertError(this.state.language.length > 0
          ? this.getLanguage(1010416)
          : "");
      }
    }
    if (!this.state.postalCode) {
      return this.AlertError("Please insert post code !!");
    }
    if (this.state.postalCode) {
      if (!this.state.num_validate.test(this.state.postalCode)) {
        return this.AlertError(
          this.state.language.length > 0
            ? this.getLanguage(1010423)
            : ""
        );
      }
    }

    if (!this.state.province_id) {
      return this.AlertError("Please select province !!");
    }
    if (!this.state.district_id) {
      return this.AlertError("Please select district !!");
    }
    if (!this.state.sub_district_id) {
      return this.AlertError("Please select sub district !!");
    }

    if (
      this.state.locationName,
      this.state.firstName,
      this.state.lastName,
      this.state.address,
      this.state.apartment,
      this.state.address3,
      this.state.phone,
      this.state.postalCode,
      this.state.province_id,
      this.state.district_id,
      this.state.sub_district_id
    ) {

      const apiUrls = this.state.setToyou.privilege === "privilege" ? `${apiUrl}/customerAddress` : `${apiUrl}/toyou/siteAddress`;
      const result = await axios({
        method: "post",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token[5]}`,
        },
        data: {
          location_name: this.state.locationName,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address1: this.state.address,
          address2: this.state.apartment === "" ? " " : this.state.apartment,
          address3: this.state.address3 === "" ? " " : this.state.address3,
          phone: this.state.phone,
          post_code: this.state.postalCode,
          province: this.state.province_select,
          district: this.state.district,
          sub_district: this.state.sub_district,
          province_id: Number(this.state.province_id),
          district_id: this.state.district_id,
          sub_district_id: this.state.sub_district_id,
          is_save: this.state.checkbox,
        },
      })
        .then(async (res) => {
          this.createShiping(res.data.id);

        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              open: true,
              message: error.response.data.message,
            });
          }
        });
    }
  };


  createAddressSelect = async () => {
    this.setState({
      check_on_click: false,
    });
    if (Number(this.state.shippingAddressData) >= 0) {
      let customerAddressId = this.state.addressData[
        this.state.shippingAddressData
      ].id;
      this.createShiping(customerAddressId);
    }
  };

  createShiping = async (customerAddressId) => {
    let url = window.location.href;
    let token = url.split("/");
    const apiUrls = this.state.setToyou.privilege === "privilege" ? `${apiUrl}/privilege/redeem` : `${apiUrl}/toyou/privilege/redeemMultiVoucher`;
    var data = {}
    if (this.state.setToyou.privilege === "privilege") {
      data = {
        dealId: this.state.id_privilege,
      }
    } else {
      data = {
        dealId: Number(this.state.id_privilege),
        qty: this.state.qty,
        account_no: this.state.account_no ? this.state.account_no : ""
      }
    }
    const result = await axios({
      method: "post",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token[5]}`,
      },
      data: data,
    })
      .then(async (response) => {
        if (response.status === 200) {

          let apiUrlShiping = "";
          let data_send = {};

          if (this.state.setToyou.privilege === "privilege") {
            apiUrlShiping = `${apiUrl}/customerAddress/createShiping`;
            data_send = {
              privilegeRedeemId: String(response.data.privilege_redeem_id),
              customerAddressId: String(customerAddressId)
            }
          } else {
            apiUrlShiping = `${apiUrl}/toyou/siteAddress/createShipping`;

            if (this.props.location.state.qty > 1) {
              let array = []

              response.data.map((item, key) => {
                array.push(item.id)
              })

              data_send = {
                privilegeRedeemId: array,
                siteAddressId: String(customerAddressId)
              }
            } else {
              data_send = {
                privilegeRedeemId: String(response.data.id),
                siteAddressId: String(customerAddressId)
              }
            }

          }
          console.log(data_send)

          let resultShiping = await axios({
            method: "post",
            url: apiUrlShiping,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token[5]}`,
            },
            data: data_send,
          })
            .then(async () => {
              this.setState({
                openSeccess: true,
                message:
                  this.state.language.length > 0
                    ? this.getLanguage(1010609)
                    : "Rewards successfully redeemed.",
              });
              this.setState({
                check_on_click: false,
              });
            })
            .catch((err) => {
              this.setState({
                open: true,
                message: err.message,
              });
              this.setState({
                check_on_click: false,
              });
            });
        } else {
          this.setState({
            open: true,
            message: response.message,
          });
          this.setState({
            check_on_click: false,
          });
        }
      })
      .catch((error) => {

        if (error.response) {
          this.setState({
            open: true,
            message: error.response.data.message,
          });

          this.setState({
            check_on_click: false,
          });
        }

      });
  };


  handleChangeShippingAddressData(event) {
    this.setState({
      shippingAddressData: event.target.value,
    });
  }

  handleProvinceChange(province_id) {
    if (province_id) {
      this.setState({ province_id: province_id })
      this.getDistricts(Number(province_id))
    }
  }

  getSubDistricts(district_id) {
    if (this.state.locations.length > 0) {
      let item = this.state.locations.find((l) => Number(l.id) === Number(district_id));
      this.setState({
        district: item,
        district_id: district_id,
        sub_district_selete: item.subDistricts,
      });
      if (item.subDistricts.length === 1) {
        this.setState({ sub_district_id: item.subDistricts[0].id })
      }
    }
  }
  handleSubDistricts(sub_district_id) {
    if (sub_district_id) {
      this.setState({ sub_district_id: sub_district_id })
    }
  }
  render() {
    const {
      data,
      addressData,
      shippingAddressData,
      openSeccess,
      open,
      language,
    } = this.state;

    return (
      <div
        className="container"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <div className="col">
          <span
            style={{
              fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                ? "DB_Heavent_Bd"
                : "Delivery_A"
                }`,
              fontSize: "30px",
            }}
          >
            {language.length > 0
              ? this.getLanguage(1010602)
              : "DELIVER TO MY ADDRESS"}
          </span>
        </div>
        <div className="col">
          <span
            style={{
              fontSize: "25px",
              color: "rgba(72, 72, 72, 1)",
              fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                ? "DB_Heavent_Bd"
                : "Delivery_A"
                }`,
            }}
          >
            {language.length > 0
              ? this.getLanguage(1010603)
              : "PLEASE ENTER YOUR SHIPPING ADDRESS."}
          </span>
        </div>
        <div className="row">
          <div className="col">
            {this.state.addressData.length > 0 ? (
              <div className="col" style={{ marginTop: "50px" }}>
                <div
                  style={{
                    fontSize: "25px",
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Bd"
                      : "Delivery_A"
                      }`,
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {language.length > 0
                    ? this.getLanguage(1010604)
                    : "Select shipping address"}
                </div>

                <div style={{ marginTop: "22px" }}>
                  <select
                    className="form-control"
                    style={{
                      width: "100%",
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: "18px",
                    }}
                    onChange={this.handleChangeShippingAddressData.bind(this)}
                  >
                    <option value="-1">
                      {language.length > 0
                        ? this.getLanguage(1010421)
                        : "Please select ..."}
                    </option>
                    {this.state.addressData.map((item, key) => {
                      return (
                        <option key={key} value={key}>
                          {item.location_name}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    className="col"
                    style={{
                      marginTop: "22px",
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: "18px",
                    }}
                  >
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].address1
                      : ""}{" "}
                    <br />
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].address2
                      : ""}{" "}
                    <br />
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].address3
                      : ""}{" "}
                    <br />
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].phone
                      : ""}{" "}
                    <br />
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].location_name
                      : ""}{" "}
                    <br />
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].province.name_en
                      : ""}{" "}
                    {addressData[shippingAddressData] !== undefined
                      ? addressData[shippingAddressData].post_code
                      : ""}{" "}
                    <br />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col" style={{ marginTop: "50px" }}>
              <div
                style={{
                  fontSize: "25px",
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    }`,
                }}
              >
                {language.length > 0
                  ? this.getLanguage(1010605)
                  : "Please select ..."}
              </div>

              <div
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                }}
              >
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <input
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={(e) => {
                        this.setState({ firstName: e.target.value ? e.target.value : undefined })
                      }}
                      className="form-control"
                      placeholder={
                        this.state.language.length > 0
                          ? this.getLanguage(1010405)
                          : "First Name"
                      }
                    />

                    {this.state.firstName === undefined && Number(this.state.shippingAddressData) === -1 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010414)
                          : ""}</div>
                      : ""
                    }
                  </div>

                  <div className="col">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      value={this.state.lastName}
                      onChange={(e) => {
                        this.setState({ lastName: e.target.value ? e.target.value : undefined })
                      }}
                      placeholder={
                        this.state.language.length > 0
                          ? this.getLanguage(1010406)
                          : "Last Name"
                      }
                    />
                    {this.state.lastName === undefined && Number(this.state.shippingAddressData) === -1 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010415)
                          : ""}</div>
                      : ""
                    }
                  </div>
                </div>

                <input
                  type="text"
                  name="locationName"
                  className="form-control"
                  value={this.state.locationName}
                  onChange={(e) => {
                    this.setState({ locationName: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010401)
                      : "Location Name"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.locationName === undefined && Number(this.state.shippingAddressData) === -1 ?
                  <div style={{ color: "red" }}>{
                    this.state.language.length > 0
                      ? this.getLanguage(1010412)
                      : ""}</div>
                  : ""
                }
                {this.state.locationName && Number(this.state.shippingAddressData) === -1 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.locationName) ?
                      <div style={{ color: "red" }}>location name must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={this.state.address}
                  onChange={(e) => {
                    this.setState({ address: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010402)
                      : "Address 1"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.address === undefined && Number(this.state.shippingAddressData) === -1 ?
                  <div style={{ color: "red" }}>{
                    this.state.language.length > 0
                      ? this.getLanguage(1010413)
                      : ""}</div>
                  : ""
                }
                {this.state.address && Number(this.state.shippingAddressData) === -1 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.address) ?
                      <div style={{ color: "red" }}>Address1 must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="text"
                  name="apartment"
                  className="form-control"
                  value={this.state.apartment}
                  onChange={(e) => {
                    this.setState({ apartment: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010403)
                      : "Address 2"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.apartment && Number(this.state.shippingAddressData) === -1 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.apartment) ?
                      <div style={{ color: "red" }}>Address 2 must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="text"
                  name="address3"
                  className="form-control"
                  value={this.state.address3}
                  onChange={(e) => {
                    this.setState({ address3: e.target.value ? e.target.value : undefined })
                  }}
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010404)
                      : "Address 3"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />
                {this.state.address3 && Number(this.state.shippingAddressData) === -1 ?
                  <>
                    {!this.state.eng_num_validate.test(this.state.address3) ?
                      <div style={{ color: "red" }}>Address 3 must be english and number only.</div>
                      : ""}
                  </>
                  : ""
                }

                <input
                  type="tal"
                  id="phone"
                  name="phone"
                  maxLength="10"
                  value={this.state.phone}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value ? e.target.value : undefined })
                  }}
                  className="form-control small-input no-arrow"
                  placeholder={
                    this.state.language.length > 0
                      ? this.getLanguage(1010407)
                      : "Phone"
                  }
                  style={{ width: "100%", marginTop: "20px" }}
                />

                {this.state.phone === undefined && Number(this.state.shippingAddressData) === -1 ?
                  <div style={{ color: "red" }}>{
                    this.state.language.length > 0
                      ? this.getLanguage(1010416)
                      : ""}</div>
                  : ""
                }
                {this.state.phone && Number(this.state.shippingAddressData) === -1 ?
                  <>
                    {this.state.phone.length < 9 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010422)
                          : ""}</div>
                      : ""
                    }
                    {!this.state.num_validate.test(this.state.phone) ?
                      <div style={{ color: "red" }}>{this.state.language.length > 0
                        ? this.getLanguage(1010416)
                        : ""}</div>
                      : ""}
                  </>
                  : ""
                }

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <input
                      type="text"
                      name="postalCode"
                      maxLength="5"
                      value={this.state.postalCode}
                      onChange={(e) => {
                        this.setState({ postalCode: e.target.value ? e.target.value : undefined })
                        if (Number(e.target.value.length) === 5) {
                          this.getLocation(e.target.value);
                        }
                        if (e.target.value.length != 5) {
                          this.setState({
                            locations: [],
                            locations_district: [],
                            sub_district: [],
                            district: [],
                            province: [],
                            province_id: "",
                            province_select: {},
                            districtData: {},
                            district_id: "",
                            sub_district_selete: [],
                            sub_district_id: "",
                          });
                        }
                      }}
                      placeholder={
                        this.state.language.length > 0
                          ? this.getLanguage(1010409)
                          : "Post code"
                      }
                      className="form-control no-arrow"
                    />
                    {this.state.postalCode === undefined && Number(this.state.shippingAddressData) === -1 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010423)
                          : ""}</div>
                      : ""
                    }
                    {this.state.postalCode && Number(this.state.shippingAddressData) === -1 ?
                      <>
                        {!this.state.num_validate.test(this.state.postalCode) ?
                          <div style={{ color: "red" }}>{this.state.language.length > 0
                            ? this.getLanguage(1010423)
                            : ""}</div>
                          : ""}
                      </>
                      : ""
                    }
                  </div>

                  <div className="col">
                    {this.state.province.length > 0 ? (
                      <select
                        name="province"
                        component="select"
                        className="form-control"
                        value={this.state.province_id}
                        onChange={(e) => this.handleProvinceChange(e.target.value)}
                      >

                        {this.state.province.length > 1 ? (
                          <>
                            <option value="">
                              {this.state.language.length > 0
                                ? this.getLanguage(1010408)
                                : "Select Province"}
                            </option>
                            {this.state.province.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {this.state.province.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            })}
                          </>
                        )}

                      </select>
                    ) : (
                      <select
                        name="province"
                        component="select"
                        className="form-control"
                      >
                        <option value="">
                          {this.state.language.length > 0
                            ? this.getLanguage(1010408)
                            : "Select Province"}
                        </option>
                      </select>
                    )}
                    {this.state.postalCode === undefined && !this.state.province_id && Number(this.state.shippingAddressData) === -1 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010430)
                          : ""}</div>
                      : ""
                    }
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <select
                      name="district"
                      component="select"
                      className="form-control"
                      value={this.state.district_id}
                      onChange={(e) => this.getSubDistricts(e.target.value)}
                    >
                      {this.state.locations.length === 1 ? (
                        <>
                          <option value={this.state.districtData.id}>
                            {this.state.lang_check.toLowerCase() === "en"
                              ? this.state.districtData.name_en
                              : this.state.districtData.name_th}
                          </option>
                        </>
                      ) : (
                        <>
                          <option value="">
                            {this.state.language.length > 0
                              ? this.getLanguage(1010426)
                              : "Please Select District"}
                          </option>

                          {this.state.locations_district.map(
                            (data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            }
                          )}
                        </>
                      )}
                    </select>

                    {this.state.postalCode === undefined && !this.state.district_id && Number(this.state.shippingAddressData) === -1 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010428)
                          : ""}</div>
                      : ""
                    }

                  </div>
                  <div className="col">
                    <select
                      name="sub_district"
                      component="select"
                      className="form-control"
                      value={this.state.sub_district_id}
                      onChange={(e) => this.handleSubDistricts(e.target.value)}

                    >
                      {this.state.sub_district_selete.length === 1 ?
                        <>
                          <option value={this.state.sub_district_selete[0].id}>
                            {this.state.lang_check.toLowerCase() === "en"
                              ? this.state.sub_district_selete[0].name_en
                              : this.state.sub_district_selete[0].name_th}
                          </option>
                        </>
                        :
                        <>
                          <option value="">
                            {this.state.language.length > 0
                              ? this.getLanguage(1010427)
                              : "Please select sub district"}
                          </option>
                          {this.state.sub_district_selete
                            ? this.state.sub_district_selete.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {this.state.lang_check.toLowerCase() ===
                                    "en"
                                    ? data.name_en
                                    : data.name_th}
                                </option>
                              );
                            })
                            : null}
                        </>
                      }
                    </select>

                    {this.state.postalCode === undefined && !this.state.sub_district_id && Number(this.state.shippingAddressData) === -1 ?
                      <div style={{ color: "red" }}>{
                        this.state.language.length > 0
                          ? this.getLanguage(1010429)
                          : ""}</div>
                      : ""
                    }

                  </div>
                </div>

                <div
                  className="col"
                  style={{ marginLeft: "5px", marginTop: "20px" }}
                >
                  <span>
                    <Checkbox
                      checked={this.state.checkbox}
                      onChange={(e) => this.setState({ checkbox: e.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />

                    {this.state.language.length > 0
                      ? this.getLanguage(1010606)
                      : "Save this address for the next time."}
                  </span>
                  {/* 
                        <input
                          disabled={
                            Number(this.state.shippingAddressData) >= 0
                              ? true
                              : false
                          }
                          type="checkbox"
                          className="form-check-input"
                          checked={this.state.checkbox}
                          onChange={this.handleChangeCheckbox}
                        />
                        <span>Save this address for the next time.</span> */}
                </div>


                <div className="row " style={{ marginTop: "40px" }}>
                  <div className="col">
                    <button
                      style={{
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Bd"
                          : "Delivery_A"
                          }`,
                        fontSize: "18px",
                      }}
                      className="backaddress"
                      onClick={() =>
                        this.props.history.push(
                          `/${this.state.setToyou.privilege}/${this.state.token}/${this.state.lang_check}`
                        )
                      }
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010410)
                        : "Back to home"}
                    </button>
                  </div>
                  <div className="col">
                    {/* {this.state.check_on_click ? (
                      <button
                        type="submit"
                        className="backShipping"
                        disabled
                        style={{
                          fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                            ? "DB_Heavent_Bd"
                            : "Delivery_A"
                            }`,
                          fontSize: "18px",
                        }}
                      >
                        {this.state.language.length > 0
                          ? this.getLanguage(1010503)
                          : "Redeem"}
                      </button>
                    ) : ( */}
                    <button
                      type="submit"
                      onClick={() => { this.handleSubmit() }}
                      className="backShipping"
                      style={{
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Bd"
                          : "Delivery_A"
                          }`,
                        fontSize: "18px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010503)
                        : "Redeem"}
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div
            className="col"
            style={{ marginTop: "50px", textAlign: "center" }}
          >
            <div className="col">
              <img
                src={data.deal_image}
                style={{
                  height: "450px",
                  width: "450px",
                }}
              />
            </div>
            <div
              className="col"
              style={{
                marginTop: "40px",
                fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                  ? "DB_Heavent_Bd"
                  : "Delivery_A"
                  }`,
                fontSize: "28px",
                textAlign: "center",
              }}
            >
              {data.deal_name}
            </div>
          </div>
        </div>

        <Modal
          open={openSeccess}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center">
                  <svg
                    width="10em"
                    height="10em"
                    viewBox="0 0 16 16"
                    className="bi bi-check-circle-fill text-success"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  <span style={{ fontSize: "30px" }}>{this.state.message}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="nav-link modal_button"
                  onClick={() => this.goTo()}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010306)
                    : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal open={open} disablePortal disableEnforceFocus disableAutoFocus>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center m-3">
                  <svg
                    width="8em"
                    height="8em"
                    viewBox="0 0 16 16"
                    className="bi bi-x-circle-fill text-danger"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                    />
                  </svg>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  {this.state.message}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.handleClose()}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010506)
                    : "Close"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Shipping;
