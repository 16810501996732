import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";
import "./Unsubscribe.css";
class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      massager: "Unsubscribe email successful.",
      lang_check: "en",
    };
  }
  componentDidMount() {
    var url = window.location.href;
    let lang = url.split("/");

    if (lang[4].toLowerCase() === "en") {
      this.setState({
        massager: "Unsubscribe email successful.",
        lang_check: lang[4],
      });
    } else {
      this.setState({
        massager: "ยกเลิกการรับข้อมูลข่าวสารทางอีเมลเรียบร้อยแล้ว.",
        lang_check: lang[4],
      });
    }
  }

  render() {
    return (
      <div className="EXTENDER">
        <p
          className="text-center PADDER-CENTER"
          contentEditable="true"
          style={{
            fontFamily: `${
              this.state.lang_check.toLowerCase() === "th"
                ? "DB_Heavent_Bd"
                : "Delivery_A"
            }`,
            fontSize: "30px",
          }}
        >
          {this.state.massager}
        </p>
      </div>
    );
  }
}

export default Unsubscribe;
