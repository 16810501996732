import React, { Component } from "react";
import Icon_Points from "../../assets/icon/Icon_Points.png";
import axios from "axios";
import { apiUrl, COLOR_PRIMARY, COLOR_SECONDARY, id_voucher_owner } from "../../constants";
import Modal from "@material-ui/core/Modal";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import "./Detail.css";
import Swal from "sweetalert2";
import NumberFormat from 'react-number-format';
import { InputAdornment } from '@material-ui/core';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      inputMode="decimal"
      decimalScale={2}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value ? values.value : 0.00
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_privilege: null,
      is_delivery: null,
      data: {},
      check_tag: null,
      open: false,
      openCheck: false,
      openSeccess: false,
      message: "",
      send: "",
      end: "",
      lang_check: "",
      language: [],
      accountNumber: [],
      check_on_click: false,
      tagData: [],
      selectItem: [],
      qty: 1,
      setToyou: {
        Page: "",
        privilege: "",
        shipping: "",
        Reward: "",
      },
    };
  }
  async componentDidMount() {
    console.log(id_voucher_owner)
    const path = window.location.pathname
    var n = path.search("DetailToyou");
    var check_page = Number(n) > 0 ? "detailToyou" : "detail"

    if (Number(n) > 0) {
      this.setState({
        setToyou: {
          Page: "detailToyou",
          privilege: "accountCustomer",
          shipping: "shippingToyou",
          Reward: "RewardToyou",
        }
      });
    } else {
      this.setState({
        setToyou: {
          Page: "detail",
          privilege: "privilege",
          shipping: "shipping",
          Reward: "Reward"
        }
      });
    }

    this.setState({
      qty: 1,
      id_privilege: this.props.match.params.id,
    });
    let token = this.props.match.params.token;
    let lang = this.props.match.params.lang;
    this.setState({ lang_check: lang });

    this.getCustomers(token, lang, check_page);
    this.getAccountCustomer(token);
    this.getPrivilegeDetail(token, this.props.match.params.id, check_page);
    this.setState({ token: token });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, openCheck: false, check_on_click: false });
  };

  AlertError = (message) => {
    Swal.fire("Redeem !", message, "error");
  };

  getAccountCustomer = (token) => {
    const resultAll = axios({
      method: "get",
      url: `${apiUrl}/site/accounts`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      }
    })
      .then(async (res) => {
        if (res.data) {
          console.log(res.data)
          this.setState({
            accountNumber: res.data,
          });
        } else {
          this.setState({
            accountNumber: [{ account_no: "No data" }],
          });
        }
      });
  };

  getDealTags = (tagData) => {
    try {
      return tagData.find((t) => Number(t.tag_id) === Number(id_voucher_owner)).tag_id;
    } catch (error) {
      return null;
    }
  };

  getPrivilegeDetail = async (token, id_privilege, check_page) => {
    const apiUrls = check_page === "detail" ? `${apiUrl}/privilege/deal/${id_privilege}` : `${apiUrl}/toyou/privilege/deal/${id_privilege}`;
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {

      let check_tag = this.getDealTags(res.data.dealTags)
      this.setState({
        data: res.data,
        is_delivery: res.data.is_delivery,
        check_tag: check_tag
      });



    });
  };

  getCustomers = async (token, lang, check_page) => {
    if (check_page === "Detail") {
      const apiUrls = `${apiUrl}/account/profile`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });

    } else {
      const apiUrls = `${apiUrl}/site/me`;
      const resultAll = axios({
        method: "get",
        url: apiUrls,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {

          this.setState({
            point: res.data.data.point_txt,
          });
          localStorage.setItem("Customer", JSON.stringify(res.data));
          localStorage.setItem("Token", JSON.stringify(token));
          localStorage.setItem("lang_check", JSON.stringify(lang));
          this.setState({ token: token });

          if (lang.toLowerCase() === "en" || lang.toLowerCase() === "th") {
            let langu = JSON.parse(localStorage.getItem(`lang_${lang.toLowerCase()}`));
            this.setState({ language: langu });
          } else if (lang === null || lang === undefined) {
            let langu = JSON.parse(localStorage.getItem("lang_th"));
            this.setState({ language: langu });
          }
        })
        .catch((err) => {
          this.props.history.push(`/Error/${this.state.lang_check}`);
        });
    }
  };

  goTo = async () => {
    if (this.state.is_delivery) {
      this.handleClose();
      this.props.history.push({
        pathname: `/${this.state.setToyou.shipping}/${this.state.id_privilege}/${this.state.token}/${this.state.lang_check}`,
        state: {
          id_privilege: this.state.id_privilege,
          qty: this.state.qty ? this.state.qty : "",
          account_no: this.state.selectItem.account_no ? this.state.selectItem.account_no : ""
        },
      });
    } else {
      this.handleClose();
      this.props.history.push({
        pathname: `/${this.state.setToyou.Reward}/${this.state.token}/${this.state.lang_check}`,
        state: {
          toHistory: 1,
        },
      });
    }
  };

  saveRedeem = async () => {
    this.setState({
      check_on_click: true,
    });

    if (this.state.is_delivery) {
      this.goTo();
    } else {
      let data_detail = {}
      let apiUrls = ""
      if (this.state.setToyou.Page === "detail") {
        apiUrls = `${apiUrl}/privilege/redeem`
        data_detail = {
          dealId: this.state.id_privilege
        }

        const result = await axios({
          method: "post",
          url: apiUrls,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data_detail,
        })
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data)
              this.setState({
                openSeccess: true,
                message:
                  this.state.language.length > 0
                    ? this.getLanguage(1010701) +
                      " : " +
                      this.state.setToyou.Page === "detail" ? response.data.privilege_code : response.data.data.coupon_code
                    : "Code" + " : " + this.state.setToyou.Page === "detail" ? response.data.privilege_code : response.data.data.coupon_code,
                send:
                  this.state.language.length > 0
                    ? this.getLanguage(1010703)
                    : "This code has sent to your email.",
                end: this.convert_time(
                  response.data.deal_obj ? response.data.deal_obj.dealRules[0].end_date : ""
                ),
              });

              this.setState({
                check_on_click: false,
              });
            } else {
              this.setState({
                openSeccess: true,
                message: response.message,
              });

              this.setState({
                check_on_click: false,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              this.setState({
                open: true,
                message: error.response.data.message,
              });

              this.setState({
                check_on_click: false,
              });
            }
          });

      } else {

        if (this.state.selectItem.length <= 0 && this.state.check_tag) {
          this.setState({
            check_on_click: false,
          });
          return this.AlertError("Please select account no !!");
        } else {
          apiUrls = `${apiUrl}/toyou/privilege/redeemMultiVoucher`
          data_detail = {
            dealId: this.state.id_privilege,
            account_no: this.state.selectItem.account_no ? this.state.selectItem.account_no : "",
            qty: this.state.qty
          }

          const result = await axios({
            method: "post",
            url: apiUrls,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.state.token}`,
            },
            data: data_detail,
          })
            .then((response) => {
              this.setState({
                openSeccess: true,
                message: this.state.language.length > 0
                  ? this.getLanguage(1010702)
                  : "Thank you",
              });

              this.setState({
                check_on_click: false,
              });
            })
            .catch((error) => {
              if (error.response) {
                this.setState({
                  open: true,
                  message: error.response.data.message,
                });

                this.setState({
                  check_on_click: false,
                });
              }
            });

        }
      }
    }
  };

  convert_time = (string_time) => {
    var timestamp = string_time.replace(/\/Date\((.*?)\)\//g, "$1");
    var date = new Date(timestamp);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    var h = date.getHours();
    var m = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

    return `${day}/${month}/${year}`;
  };

  redeem = async () => {
    var data = {}

    if (this.state.selectItem.length <= 0 && this.state.check_tag) {
      this.setState({
        check_on_click: false,
      });
      return this.AlertError("Please select account no !!");
    }
    const apiUrls = this.state.setToyou.Page === "detail" ? `${apiUrl}/privilege/redeemValidatePoint` : `${apiUrl}/toyou/privilege/redeemValidatePointMultiVoucher`;

    if (this.state.setToyou.Page === "detail") {
      data = {
        deal_id: this.state.id_privilege,
      }
    } else {
      if (this.state.qty <= 0) {
        return this.AlertError("Redeem amount must more than 0.");
      }

      data = {
        deal_id: this.state.id_privilege,
        qty: this.state.qty,
        account_no: this.state.selectItem.account_no ? this.state.selectItem.account_no : ""
      }
    }

    const result = await axios({
      method: "post",
      url: apiUrls,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${this.state.token}`,
      },
      data: data,
    })
      .then((response) => {
        if (response.data.success) {
          if (this.state.id_privilege) {
            this.setState({
              openCheck: true,
              message:
                this.state.language.length > 0
                  ? this.getLanguage(1010601)
                  : "Redemption confirm?",
            });
          } else {
            this.setState({
              open: true,
              message: "Date id privilege null.",
            });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          let message = this.state.language.length > 0
            ? this.getLanguage(306)
            : "You have reached your quota limit."
          // console.log(message)

          if (error.response.data.errorCode === 306) {
            this.setState({
              open: true,
              message: message,
            });
          } else {
            this.setState({
              open: true,
              message: error.response.data.message,
            });
          }
        }
      });
  };
  getLanguage = (code) => {
    try {
      return this.state.language.find((l) => Number(l.code) === Number(code))
        .content;
    } catch (error) {
      return "";
    }
  };

  setDegitSaraly = (point) => {
    if (point) {
      var parts = point.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let pointa = parts.join(".");
      return pointa;
    } else {
      return " ";
    }
  };
  render() {
    const { data, openCheck, open, openSeccess } = this.state;
    return (
      <div className="container" style={{ marginTop: "50px" }}>
        <div className="row d-block d-lg-flex mb-3">
          <div className="col col-lg-6">
            <img
              src={data.deal_image}
              style={{
                width: "100%",
                maxWidth: "450px",
              }}
            />
          </div>
          <div className="col col-lg-4">
            <div className="row" style={{ marginTop: "20px" }}>
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Bd"
                    : "Delivery_A"
                    }`,
                  fontSize: "28px",
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 1)",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {this.state.lang_check === "TH"
                  ? data.deal_name_th
                  : data.deal_name}
              </span>
            </div>

            <div className="row" style={{ marginTop: "20px" }}>
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  fontSize: "22px",
                  color: "rgba(0, 0, 0, 1)",
                  marginLeft: "10px",
                }}
              >
                {this.state.language.length > 0
                  ? this.getLanguage(1010501)
                  : "Description"}
              </span>{" "}
            </div>
            <div className="row" style={{ marginTop: "15px" }}>
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  paddingLeft: "10px",
                  fontSize: "18.5px",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.lang_check.toLowerCase() === "th"
                      ? data.deal_detail_short_th
                      : data.deal_detail_short,
                }}
              />
            </div>
            <div className="row" style={{ marginTop: "53px" }}>
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                {data.deal_detail_condition ? "TERMS AND CONDITIONS" : null}
              </span>
            </div>
            <div className="row mt-1">
              <span
                style={{
                  fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                    ? "DB_Heavent_Med"
                    : "Delivery_A"
                    }`,
                  fontSize: "18px",
                }}
                dangerouslySetInnerHTML={{ __html: data.deal_detail_condition }}
              />
            </div>

            {!data.redeemable || this.state.setToyou.Page === "detail" ? null :
              <div className="row justify-content-between" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <div className="col" style={{ textAlign: "left" }}>
                  <img
                    src={Icon_Points}
                    style={{ height: "25px", width: "25px" }}
                  />
                  <span
                    style={{
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Med"
                        : "Delivery_A"
                        }`,
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 1)",
                      marginLeft: "5px",
                    }}
                  >
                    {Number(data.point) > 0 ? this.setDegitSaraly(data.point) : 0}{" "}
                    <span
                      style={{
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Med"
                          : "Delivery_A"
                          }`,
                        fontSize: "18px",
                        color: "rgba(166, 166, 166, 1)",
                      }}
                    >
                      {" "}
                      {this.state.language.length > 0
                        ? this.getLanguage(1010101)
                        : "Point"}
                    </span>
                  </span>
                </div>
                <div className="col">
                </div>
              </div>
            }
            {this.state.setToyou.Page === "detail" || !this.state.check_tag || !data.redeemable ? null :
              <div className="row">
                <div className="col">

                  <div
                    className="form-inline"
                    style={{
                      marginBottom: "25px",
                    }}
                  >
                    <Autocomplete
                      // multiple
                      id="grouped-demo"
                      options={this.state.accountNumber}
                      getOptionLabel={(option) => option.account_no}
                      getOptionSelected={(option, value) => option.account_no === value.account_no}
                      value={this.state.selectItem}
                      onChange={(event, newValue) => {
                        this.setState({ selectItem: newValue });
                      }}
                      style={{
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      renderInput={(params) =>
                        <TextField {...params} variant="outlined" />
                      }
                    />

                  </div>
                </div>
              </div>
            }

            <div className="row justify-content-between" style={{ alignItems: "center" }}>
              <div className="col">
                {!data.redeemable && Number(data.actualCodeRemaining) === 0 || this.state.setToyou.Page === "detail" && Number(data.actualCodeRemaining) === 0 ?
                  <div className="col">
                    <img
                      src={Icon_Points}
                      style={{ height: "25px", width: "25px" }}
                    />
                    <span
                      style={{
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Med"
                          : "Delivery_A"
                          }`,
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 1)",
                        marginLeft: "5px",
                      }}
                    >
                      {Number(data.point) > 0 ? this.setDegitSaraly(data.point) : 0}{" "}
                      <span
                        style={{
                          fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                            ? "DB_Heavent_Med"
                            : "Delivery_A"
                            }`,
                          fontSize: "18px",
                          color: "rgba(166, 166, 166, 1)",
                        }}
                      >
                        {" "}
                        {this.state.language.length > 0
                          ? this.getLanguage(1010101)
                          : "Point"}
                      </span>
                    </span>
                  </div>
                  :

                  <TextField
                    value={Number(this.state.qty)}
                    onChange={(e) => {
                      this.setState({ qty: parseInt(e.target.value) })
                    }}
                    label="Amount"
                    variant="outlined"
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                }
              </div>
              <div className="col">
                {data.redeemable && Number(data.actualCodeRemaining) > 0 ? (
                  this.state.check_on_click ? (
                    <button
                      className="nav-link redeem"
                      style={{
                        background: `${COLOR_SECONDARY}`,
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Bd"
                          : "Delivery_A"
                          }`,
                        fontSize: "18px",
                      }}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010503)
                        : "แลก"}
                    </button>
                  ) : (
                    <button
                      className="nav-link redeem"
                      style={{
                        background: `${COLOR_SECONDARY}`,
                        fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                          ? "DB_Heavent_Bd"
                          : "Delivery_A"
                          }`,
                        fontSize: "18px",
                      }}
                      onClick={() => this.redeem()}
                    >
                      {this.state.language.length > 0
                        ? this.getLanguage(1010503)
                        : "แลก"}
                    </button>
                  )
                ) : (
                  <span
                    style={{
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Bd"
                        : "Delivery_A"
                        }`,
                      fontSize: "18px",
                      color: "rgba(166, 166, 166, 1)",
                    }}
                  >
                    {this.state.language.length > 0
                      ? this.getLanguage(1010507)
                      : "สินค้าหมด"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          className="backDetail mb-2"
          style={{
            fontFamily: `${this.state.lang_check.toLowerCase() === "th"
              ? "DB_Heavent_Bd"
              : "Delivery_A"
              }`,
            fontSize: "18px",
            background: `${COLOR_SECONDARY}`,
          }}
          onClick={() =>
            this.props.history.push(
              `/${this.state.setToyou.privilege}/${this.state.token}/${this.state.lang_check ? this.state.lang_check : "en"}`
            )
          }
        >
          {this.state.language.length > 0
            ? this.getLanguage(1010304)
            : "Back to home"}
        </button>

        <Modal
          open={openCheck}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center m-3">
                  <svg
                    width="8em"
                    height="8em"
                    viewBox="0 0 16 16"
                    className="bi bi-question-circle-fill text-info"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"
                    />
                  </svg>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  <span
                    style={{
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Bd"
                        : "Delivery_A"
                        }`,
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    {this.state.message}
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.check_on_click ? (
                  <button
                    type="button"
                    className="nav-link modal_button"
                    style={{
                      background: `${COLOR_SECONDARY}`,
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Bd"
                        : "Delivery_A"
                        }`,
                      fontSize: "25px",
                    }}
                  >
                    {this.state.language.length > 0
                      ? this.getLanguage(1010306)
                      : ""}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="nav-link modal_button"
                    style={{
                      background: `${COLOR_SECONDARY}`,
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Bd"
                        : "Delivery_A"
                        }`,
                      fontSize: "25px",
                    }}
                    onClick={() => this.saveRedeem()}
                  >
                    {this.state.language.length > 0
                      ? this.getLanguage(1010306)
                      : ""}
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.handleClose()}
                  style={{
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Bd"
                      : "Delivery_A"
                      }`,
                    fontSize: "25px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010307)
                    : ""}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openSeccess}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          style={{
            fontFamily: `${this.state.lang_check.toLowerCase() === "th"
              ? "DB_Heavent_Med"
              : "Delivery_A"
              }`,
            fontSize: "22px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center m-3">
                  <svg
                    width="8em"
                    height="8em"
                    viewBox="0 0 16 16"
                    className="bi bi-check-circle-fill text-success"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                </div>

                {this.state.setToyou.Page === "DetailToyou" ?
                  <>
                    <div className="justify-content-center d-flex align-items-center">
                      {this.state.message}
                    </div>
                    {this.state.end ? (
                      <div className="justify-content-center d-flex align-items-center">
                        {this.state.language.length > 0
                          ? this.getLanguage(1010702)
                          : "Expire"}{" "}
                        : {this.state.end}
                      </div>
                    ) : null}
                    {this.state.send ? (
                      <div className="justify-content-center d-flex align-items-center">
                        {this.state.send}
                      </div>
                    ) : null}
                  </>
                  :
                  <>
                    <div className="justify-content-center d-flex align-items-center">
                      {this.state.language.length > 0
                        ? this.getLanguage(1010702)
                        : "Thank you"}
                    </div>
                  </>
                }

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="nav-link modal_button"
                  onClick={() => this.goTo()}
                  style={{
                    fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                      ? "DB_Heavent_Bd"
                      : "Delivery_A"
                      }`,
                    fontSize: "25px",
                  }}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010307)
                    : ""}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          style={{
            fontFamily: `${this.state.lang_check.toLowerCase() === "th"
              ? "DB_Heavent_Med"
              : "Delivery_A"
              }`,
            fontSize: "22px",
          }}
          open={open}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="justify-content-center d-flex align-items-center m-3">
                  <svg
                    width="8em"
                    height="8em"
                    viewBox="0 0 16 16"
                    className="bi bi-x-circle-fill text-danger"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                    />
                  </svg>
                </div>
                <div className="justify-content-center d-flex align-items-center">
                  <p
                    style={{
                      marginTop: "40px",
                      fontFamily: `${this.state.lang_check.toLowerCase() === "th"
                        ? "DB_Heavent_Bd"
                        : "Delivery_A"
                        }`,
                      fontSize: "28px",
                      textAlign: "center",
                    }}
                  >
                    {this.state.message}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.handleClose()}
                >
                  {this.state.language.length > 0
                    ? this.getLanguage(1010506)
                    : "Close"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Detail;
